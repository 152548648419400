@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-reminder-card {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-box-shadow(1);
    border-radius: 3px;
    position: relative;

    &--variant-mini {
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'border-on-background');
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    &--disabled {
        cursor: auto;
        pointer-events: none;
    }

		&__inside {
			@include fourg-flex(row, flex-start, flex-start);
			@include fourg-gutter(padding, 0.5);
		}

		&--variant-mini:not(&--error) &__inside {
			padding: 0 !important;
		}

    &__avatar {
        @include fourg-gutter(margin-left, 0.5);
        @include fourg-icon-size(32px, 20px);
        border-radius: 50%;
        overflow: hidden;
    }

    &__content {
        @include fourg-flex(column, flex-start, flex-start);
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        @include fourg-flatten-children;
        flex-grow: 1;
        min-width: 0;
        box-sizing: border-box;
    }

    &__checkbox {
        margin: -0.125rem 0 0 0;
    }

    &--is-recurring:not(&--disabled) &__checkbox {

        .fourg-checkbox__icon {

            &--checked {
                @include fourg-theme(color, 'soft-on-surface');
            }

            &--unchecked {
                @include fourg-theme(color, 'primary');
            }
        }
    }

    &__title {
        @include fourg-gutter(margin-bottom, 0.25);
        font-size: fourg-px-to-rem(13px);
        line-height: 1.2;
        font-weight: 600;
        max-width: 100%;
        min-width: 0;
    }

    &__date {
        font-size: fourg-px-to-rem(12px);
    }
    
    &__description {
        @include fourg-theme(color, 'soft-on-background');
        @include fourg-gutter(margin-top, 0.25);
        font-size: fourg-px-to-rem(12px);
    }

    &__actions {
			margin-right: -4px;
			margin-top: -4px;
	
			.fourg-action-toggle__button {
				@include fourg-transition(opacity);
				color: inherit;
				opacity: 0;
			}
		}
    
    &__actions.fourg-action-toggle--expanded .fourg-action-toggle__button,
		&:hover &__actions .fourg-action-toggle__button,
		&__actions .fourg-action-toggle__button:focus,
		&__actions .fourg-action-toggle__button:active {
			opacity: 1;
		}

    .fourg-recurrence-span {

        &__text {
            font-size: inherit;
        }
    }

		&__warnings {
			@include fourg-flex(row, center, flex-start);
			@include fourg-theme(background-color, 'error');
			@include fourg-theme(color, 'on-error');
			padding: 4px 12px;
			gap: 8px;
			margin: 0;
			font-size: fourg-px-to-rem(10px);
			font-weight: 400;
			list-style: none;
			cursor: help;
	
			.fourg-icon {
				@include fourg-icon-size(18px);
				flex-shrink: 0;
				pointer-events: none;
			}
	
			p {
				flex-grow: 1;
				margin: 0;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				min-width: 0;
				padding-top: 0.25em;
			}
		}

		&--error {
			@include fourg-static-ripple('error', 'focus');
			@include fourg-theme(color, 'error');
			@include fourg-theme(outline-color, 'error');
			outline-style: solid;
			outline-width: 2px;
		}

    &--complete &__title {
        text-decoration: line-through;
    }

    &--completable &__title {
        cursor: pointer;
    }

    &--disabled &__title {
        cursor: auto;
    }
}
