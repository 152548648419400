@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-board-column {
    @include fourg-flex(column, stretch, flex-start);
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    width: 100%;
    max-width: 250px;
    flex-grow: 1;

    &__header {
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'on-background');
        @include fourg-gutter(padding-top);
        position: sticky;
        top: 0;
        box-sizing: border-box;
        z-index: 1;
    }
   
    &__header-content {
        @include fourg-flex(row, center, flex-start);
        @include fourg-static-ripple('on-background', 'focus');
        position: sticky;
        top: 0;
        box-sizing: border-box;
        padding: 16px 20px 16px;
        z-index: 1;
        border-radius: 8px 8px 0 0;
    }

    &__label {
        font-size: fourg-px-to-rem(14px);
        font-weight: 700;
        box-sizing: border-box;
        margin: 0;
    }

    &__total {
        @include fourg-theme(color, 'soft-on-background');
        display: block;
        padding: 0 8px;
        font-size: fourg-px-to-rem(12px);
        margin: 0;
        box-sizing: border-box;
    }

    &__handles {
        @include fourg-static-ripple('on-background', 'focus');
        flex-grow: 1;
        box-sizing: border-box;
        padding: 4px 8px 0;
    }

    &__loader {
        @include fourg-static-ripple('on-background', 'focus');
        background-color: transparent;
        flex-grow: 1;
    }

    &--highlighted {

        .fourg-board-handle {
            transform: none !important;
        }
    }

    &--highlighted &__header-content,
    &--highlighted &__handles {
        @include fourg-static-ripple('on-background', 'hover');
    }

    &--highlighted:hover &__header-content,
    &--highlighted:hover &__handles {
        @include fourg-static-ripple('on-background', 'active');
    }
}
