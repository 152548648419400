@import '~draft-js/dist/Draft.css';
@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-editor {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    position: relative;
    min-width: 0;

    &--read-only {
        background-color: transparent;
        border-color: transparent;
    }

    &:not(&--read-only) {

        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            @include fourg-gutter(padding-top, 0.5);
            @include fourg-gutter(padding-bottom, 0.5);
            @include fourg-gutter(padding-left);
            @include fourg-gutter(padding-right);
            box-sizing: border-box;
        }
    }

    &--disabled {
        pointer-events: none;
        cursor: auto;
    }

    &__label {
        @include fourg-screen-reader-only;
    }

    &__actions {
        @include fourg-gutter(padding, 0.25);
        @include fourg-flex(row wrap, center, flex-start);
        @include fourg-static-ripple;
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-flatten-children(true);
        border-top-width: 1px;
        border-top-style: solid;

        .fourg-button {
            @include fourg-theme(color, 'soft-on-surface');
            @include fourg-ripple('soft-on-surface');
            @include fourg-gutter(margin-right, 0.125);

            &--active {
                @include fourg-static-ripple('on-surface', 'active');
            }
        }
    }
    
    &__loader {
        @include fourg-theme(color, 'primary');
        margin-left: auto;
    }

    &__block {
        @include fourg-gutter(margin-bottom, 0.5);
    }

    &__list-item {

    }

    &__user-mention {
        @include fourg-inline-flex(row, center, center);
        @include fourg-theme(color, 'primary');
        @include fourg-static-ripple('primary', 'active');
        font-weight: 600;
        padding: 0 2px;
        border-radius: 2px;
    }

    &__link {

    }

    .fourg-action-list {

        &:not(:focus-within):not(:hover) {

            .fourg-action-list-item:first-child {

                .fourg-action-list-item__action {
                    @include fourg-static-ripple(currentColor, 'focus');
                }
            }
        }

        &--anchor-top-left,
        &--anchor-top-right {
            top: 100%;
            margin-top: 8px;
        }

        &--anchor-bottom-left,
        &--anchor-bottom-right {
            bottom: 100%;
            margin-bottom: 8px;
        }
    }

    .fourg-action-list--anchor-bottom-left,
    .fourg-action-list--anchor-bottom-right {
        bottom: 100%;
        margin-bottom: 8px;
    }

    .public-DraftStyleDefault-ol, 
    .public-DraftStyleDefault-ul {
        margin-top: 0;
    }

    .public-DraftEditor-content > div > *:last-child {
        margin-bottom: 0;
    }

    .DraftEditor-root {
        font-size: fourg-px-to-rem(14px);
    }

    &--scrollable .DraftEditor-root {
        max-height: 108px;
        overflow-y: auto;
    }
    
    .public-DraftEditorPlaceholder-root {
        @include fourg-theme(color, 'soft-on-surface');
        font-size: fourg-px-to-rem(14px);
    }

    .public-DraftEditorPlaceholder-hasFocus {
        opacity: 0;
    }

}
