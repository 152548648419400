@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-error-page {

	.fourg-page-header {
		@include fourg-screen-reader-only;
	}

	.fourg-icon-message__icon {
		@include fourg-theme(color, 'error');
	}
}
