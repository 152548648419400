@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-cietrade-form-dialog {

	&__search-form {
		border: none;
		border-radius: 0 0 8px 8px;
	}

	&__edit-form {
		border: none;
		border-radius: 0 0 8px 8px;

		.fourg-form__fields {
			padding: 0;
		}
	}

	&__loader {
		border-radius: 0 0 8px 8px;
		z-index: 10;
	}

	&__forms {
		@include fourg-flex(row, stretch, flex-start);
	}

	&__cietrade-form,
	&__crm-form {
		@include fourg-flex(row wrap, flex-start, flex-start);
		@include fourg-gutter(padding, 0.5);
		position: relative;
		align-content: flex-start;
		width: 100%;
	}

	&__cietrade-form {
		@include fourg-theme(border-right-color, 'border-on-surface');
		border-right-width: 1px;
		border-right-style: solid;

		&::before {
			@include fourg-overlay;
			@include fourg-theme(background-color, 'disabled');
			content: '';
			opacity: 0.5;
		}
	}

	&__info {
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		@include fourg-gutter(padding-top, 0.5);
		@include fourg-gutter(padding-bottom, 0.5);
		@include fourg-theme(background-color, 'background');
		@include fourg-theme(color, 'soft-on-background');
		@include fourg-theme(border-bottom-color, 'border-on-background');
		@include fourg-flatten-children;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		font-size: fourg-px-to-rem(14px);
	}

	&__error-message {

		.material-icons {
			@include fourg-theme(color, 'error');
		}
	}

	&__success-message {

		.material-icons {
			@include fourg-theme(color, 'success');
		}
	}

	&__form-field {

		&--diff {

			&::before {
				@include fourg-theme(background-color, 'highlight');
				@include fourg-theme(color, 'on-highlight');
				@include fourg-theme(border-color, 'border-on-highlight');
				position: absolute;
				top: 4px;
				left: 4px;
				right: 4px;
				bottom: 4px;
				border-radius: 6px;
				pointer-events: none;
				content: '';
			}
		}
	}
}
