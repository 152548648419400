@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-record-warnings {
  @include fourg-theme(background-color, 'error');
	@include fourg-theme(color, 'on-error');
	width: 100%;
	position: relative;

	&__header {
		@include fourg-flex(row, center, flex-start);
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		@include fourg-gutter(padding-top, 0.64);
		@include fourg-gutter(padding-bottom, 0.25);
		@include fourg-gutter(gap, 0.25);

		.fourg-icon {
			@include fourg-icon-size(20px);
		}
	}

	&__content {
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		@include fourg-gutter(padding-bottom, 0.75);
	}

	&__title {
		font-size: fourg-px-to-rem(14px);
		margin: 0;
	}

	&__list {
		@include fourg-theme(color, 'soft-on-error');
		font-size: fourg-px-to-rem(12px);
		padding: 0 0 0 1em;
		margin: 0;
	}

	&__list-item {
		@include fourg-flatten-children;
	}
}
