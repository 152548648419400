@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-table-group {
    @include fourg-flex(column, stretch, flex-start);
    flex-grow: 1;

    &__content {
        position: relative;
        flex-grow: 1;
    }

    &--scrollable &__scroller {
        @include fourg-overlay(absolute);
        overflow-y: auto; //  enforce scrollbar for scrollbar compensation on header
    }

    &__list {
        @include fourg-gutter(padding);
        @include fourg-flatten-children;
        list-style: none;
        margin: 0;
        box-sizing: border-box;
    }

    &__header {
        @include fourg-static-ripple(currentColor, 'focus');
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'border-on-background');
        border-bottom-width: 1px;
        border-bottom-style: solid;
        position: sticky;
        top: 0;
        z-index: 3;

        .fourg-table {
            @include fourg-gutter(padding-left);
            @include fourg-gutter(padding-right);
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            box-sizing: border-box;

            &__scroller {
                min-height: 0;
            }

            &__loader,
            &__not-found {
                display: none;
            }
        }

        .fourg-table__order-button {
            border: none;
        }

        .fourg-table__th {
            background-color: transparent;
        }
    }
}


