@import "../../functions";
@import "../../variables";
@import "../../mixins";

.fourg-card-list {
	width: 100%;
	position: relative;

	&__header {
		@include fourg-flex(row, center, flex-start);
		@include fourg-gutter(padding-top);
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		@include fourg-gutter(gap, 0.25);
	}

	&__title {
		font-size: fourg-px-to-rem(16px);
		margin: 0;
	}

	&__count {
		@include fourg-theme(color, "soft-on-background");
		font-size: fourg-px-to-rem(12px);
		display: block;
		flex-shrink: 0;
	}

	&__items {
		@include fourg-flex(row wrap, stretch, flex-start);
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&--gutter-size-m &__items {
		@include fourg-gutter(padding, 0.64);

		> .fourg-card-list-item {
			@include fourg-gutter(padding, 0.36);
		}
	}

	&--gutter-size-s &__items {
		@include fourg-gutter(padding, 0.85);

		> .fourg-card-list-item {
			@include fourg-gutter(padding, 0.15);
		}
	}

	&__actions {
		margin-inline-start: auto;
	}

	@for $i from 1 through 12 {
		@include fourg-respond("phone") {
			&--columns-phone-#{$i} > &__items > .fourg-card-list-item {
				width: percentage($i / 12);
			}
		}
		@include fourg-respond("tablet") {
			&--columns-tablet-#{$i} > &__items > .fourg-card-list-item {
				width: percentage($i / 12);
			}
		}
		@include fourg-respond("laptop") {
			&--columns-laptop-#{$i} > &__items > .fourg-card-list-item {
				width: percentage($i / 12);
			}
		}
		@include fourg-respond("desktop") {
			&--columns-desktop-#{$i} > &__items > .fourg-card-list-item {
				width: percentage($i / 12);
			}
		}
	}
}
