@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-location-info-input {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;
    max-height: 100%;

    &__header {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-gutter(padding);
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'border-on-background');
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &__search-container {
        position: relative;

        .fourg-input {
            margin: 0;
        }
    }

    &__loader {
        @include fourg-theme(color, 'primary');
        position: absolute;
        top: fourg-px-to-rem(14px);
        right: fourg-px-to-rem(14px);
    }

    &--variant-quiet &__loader {
        top: fourg-px-to-rem(7px);
        right: fourg-px-to-rem(6px);
    }

    &__options {
        flex-grow: 1;
        overflow-y: auto;
    }

    &__label {
        @include fourg-gutter(margin-bottom, 0.75);
        cursor: pointer;
        align-self: flex-start;
    }

    &--disabled &__label {
        @include fourg-theme(color, 'on-disabled');
        cursor: auto;
    }

    .fourg-chip-checkbox-list {
        @include fourg-gutter(padding-top, 0.25);
        @include fourg-gutter(padding-bottom, 0.25);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
    }
}
