@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-sidebar {
    @include fourg-theme(background-color, 'background');
    @include fourg-theme(color, 'on-background');
    @include fourg-theme(border-color, 'border-on-background');
    width: 100%;
    max-width: 400px;
    border-right-style: solid;
    border-right-width: 1px;
    position: relative;

    &__scroller {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-overlay(absolute);
        overflow-y: auto;
    }
}
