@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-badge {
    @include fourg-theme(background-color, 'primary');
    @include fourg-theme(color, 'on-primary');
    @include fourg-theme(border-color, 'border-on-primary');
    @include fourg-inline-flex(row, center, flex-start);
    font-size: fourg-px-to-rem(12px);
    height: fourg-px-to-rem(16px);
    padding: 0 fourg-px-to-rem(8px);
    border-radius: fourg-px-to-rem(8px);
    line-height: 1;
    max-width: 100%;
    font-weight: 700;
    box-sizing: border-box;

    &__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        flex-grow: 1;
        text-transform: uppercase;
    }

    &--variant-secondary {
        @include fourg-theme(background-color, 'secondary');
        @include fourg-theme(color, 'on-secondary');
        @include fourg-theme(border-color, 'border-on-secondary');
    }

    &--variant-success {
        @include fourg-theme(background-color, 'success');
        @include fourg-theme(color, 'on-success');
        @include fourg-theme(border-color, 'border-on-success');
    }

    &--variant-warning {
        @include fourg-theme(background-color, 'warning');
        @include fourg-theme(color, 'on-warning');
        @include fourg-theme(border-color, 'border-on-warning');
    }

    &--variant-error {
        @include fourg-theme(background-color, 'error');
        @include fourg-theme(color, 'on-error');
        @include fourg-theme(border-color, 'border-on-error');
    }

    &--variant-highlight {
        @include fourg-theme(background-color, 'highlight');
        @include fourg-theme(color, 'on-highlight');
        @include fourg-theme(border-color, 'border-on-highlight');
    }

    &--variant-registration {
        @include fourg-theme(background-color, 'registration');
        @include fourg-theme(color, 'on-registration');
        @include fourg-theme(border-color, 'border-on-registration');
    }

    &--disabled {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
    }
}
