@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-close-toast {
    @include fourg-ripple;
    @include fourg-gutter(padding, 0.25);
    font: inherit;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    color: inherit;
    border: none;
    outline: 0;
    box-shadow: none;
    border-radius: 3px;
    padding: 0;
    margin: 0;

    &:disabled {
        cursor: auto;
        pointer-events: none;
    }
}
