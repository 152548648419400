@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-remove-self-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;

        .fourg-form__fields {
            @include fourg-gutter(padding-bottom, 1.5);
        }
    }

    .fourg-icon-message {
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        @include fourg-gutter(padding-bottom, 1.5);
        @include fourg-gutter(padding-top, 1.5);
    }
}
