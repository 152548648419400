@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-address-input {

    &__city {
        @include fourg-inline-flex(column, stretch, flex-start);
        width: calc(40% - 16px);
        margin: 0 16px 0 0;
    }

    &__state-select,
    &__state-input {
        @include fourg-inline-flex(column, stretch, flex-start);
        width: calc(40% - 16px);
        margin: 0 16px 0 0;
    }

    &__zip {
        @include fourg-inline-flex(column, stretch, flex-start);
        width: 20%;
        margin: 0;
    }

    .fourg-checkbox {
        margin-bottom: 0;

        &__label {
            @include fourg-screen-reader-only;
        }
    }

    .fourg-form-field-heading {
        @include fourg-gutter(margin-bottom);
        padding-left: 0;
        padding-right: 0;

        &__content {
            padding-top: 0;
        }
    }
}
