@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-icon-message {
    @include fourg-flatten-children;
    @include fourg-flex(column, center, center);
    @include fourg-gutter(padding-top, 3);
    @include fourg-gutter(padding-bottom, 3);
    @include fourg-gutter(padding-left);
    @include fourg-gutter(padding-right);
    @include fourg-theme(color, 'soft-on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    flex-grow: 1;
    box-sizing: border-box;

    &__icon {
        @include fourg-theme(color, 'border-on-surface');
        @include fourg-icon-size(120px);
        @include fourg-gutter(margin-bottom, 0.5);
    }
    
    &__heading {
        @include fourg-theme(color, 'on-surface');
        @include fourg-gutter(margin-bottom, 0.5);
        font-size: fourg-px-to-rem(24px);
        font-weight: 300;
        text-align: center;
    }

    &__subheading {
        @include fourg-gutter(margin-bottom, 0.5);
        font-size: fourg-px-to-rem(16px);
        font-weight: 400;
        text-align: center;
    }

    &__message {
        @include fourg-flatten-children;
    }
}
