@import '../../functions';
@import '../../variables';
@import '../../mixins';

@keyframes fourg-loader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fourg-loader-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

.fourg-loader {
    @include fourg-flex(column, center, center);
    @include fourg-theme(color, 'primary');
    position: relative;

    &--absolute {
        @include fourg-overlay(absolute);
        @include fourg-theme(background-color, 'surface');
        z-index: 1;
    }

    &--fixed {
        @include fourg-overlay(fixed);
        @include fourg-theme(background-color, 'surface');
        z-index: 11;
    }

    &__svg {
        animation: fourg-loader-rotate 2s linear infinite;
    }

    &__circle {
        stroke: currentColor;
        stroke-linecap: round;
        animation: fourg-loader-dash 1.5s ease-in-out infinite;
    }
}
