@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-field-heading {
    @include fourg-gutter(padding-left, 0.5);
    @include fourg-gutter(padding-right, 0.5);
    @include fourg-gutter(margin-bottom, 0.5);
    box-sizing: border-box;
    width: 100%;

    &__content {
        @include fourg-gutter(padding-top, 0.75);
        @include fourg-gutter(padding-bottom, 0.75);
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-flex(row, center, flex-start);
        box-sizing: border-box;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &__label {
        opacity: 0.75;
        text-transform: uppercase;
        font-weight: 700;
        font-size: fourg-px-to-rem(14px);
        margin: 0;
        display: block;
        flex-grow: 1;
    }
}
