@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-board {
    display: block;
    position: relative;
    flex-grow: 1;
    width: 100%;

    &__backdrop {
        @include fourg-overlay(absolute);
    }

    &__scroller {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-overlay(absolute);
        overflow: auto;
    }

    &__board {
        @include fourg-flex(row, stretch, flex-start);
        @include fourg-transition(opacity);
        flex-grow: 1;
        width: auto;
        opacity: 1;
    }

    &--disabled &__board {
        // opacity: 0.7;
        pointer-events: none;
        cursor: auto;
    }

    &__columns {
        @include fourg-flex(row, stretch, flex-start);
        @include fourg-flatten-children(true);
        width: auto;
        flex-shrink: 0;

        &::before,
        &::after {
            @include fourg-gutter(width);
            content: '';
            display: block;
            flex-shrink: 0;
        }
    }
}
