@mixin fourg-flex($flex-flow: column, $align-items: flex-start, $justify-content: flex-start) {
  align-items: $align-items;
  display: flex;
  flex-flow: $flex-flow;
  justify-content: $justify-content;
}

@mixin fourg-inline-flex($flex-flow: column, $align-items: flex-start, $justify-content: flex-start) {
  align-items: $align-items;
  display: inline-flex;
  flex-flow: $flex-flow;
  justify-content: $justify-content;
}

@mixin fourg-clear-children {
  &::before,
  &::after {
    clear: both;
    content: '';
    display: table;
    table-layout: fixed;
  }
}

@mixin fourg-overlay($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin fourg-flatten-children($horizontal: false) {
  @if ($horizontal) {
    > *:first-child {
      margin-left: 0 !important;
    }

    > *:last-child {
      margin-right: 0 !important;
    }
  } @else {
    > *:first-child {
      margin-top: 0 !important;
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@mixin fourg-theme($prop: color, $color: 'primary', $has-var: true) {
  $theme-color: map-get($fourg-theme--map, $color);
  #{$prop}: $theme-color;
  @if ($has-var) {
    #{$prop}: var(--fourg-theme--#{$color}, $theme-color);
  }
}

@mixin fourg-respond($device: 'phone') {
  $device-map: map-get($fourg-respond--map, $device);
  $min: map-get($device-map, 'min');
  $max: map-get($device-map, 'max');
  @if ($min and $max) {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if ($min) {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else if ($max) {
    @media screen and (max-width: $max) {
      @content;
    }
  }
}

@mixin fourg-gutter($prop: margin, $multiplier: 1) {
  @include fourg-respond('phone') {
    #{$prop}: $fourg-gutter--phone * $multiplier;
  }
  @include fourg-respond('tablet') {
    #{$prop}: $fourg-gutter--tablet * $multiplier;
  }
  @include fourg-respond('laptop') {
    #{$prop}: $fourg-gutter--laptop * $multiplier;
  }
  @include fourg-respond('desktop') {
    #{$prop}: $fourg-gutter--desktop * $multiplier;
  }
}

@mixin fourg-force-aspect-ratio($w: 1, $h: 1, $prop: padding-top) {
  #{$prop}: percentage($h / $w);
  width: 100%;
}

@mixin fourg-focus-ring($color: 'primary', $spread: 1px) {
  $theme-color: map-get($fourg-theme--map, $color);
  @include fourg-theme(border-color, $color);
  box-shadow: 0 0 0 $spread $theme-color;
  box-shadow: 0 0 0 $spread var(--fourg-theme--#{$color}, $theme-color);
}

@mixin fourg-kill-focus-ring {
  border-color: transparent !important;
  box-shadow: none !important;
}

@mixin fourg-screen-reader-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

@mixin fourg-transition($props: all, $duration: 100ms, $delay: 0ms, $function: ease-out) {
  transition-property: zip($props);
  transition-duration: $duration;
  transition-delay: $delay;
  transition-timing-function: $function;
}

@mixin fourg-icon-size($px: 24px, $icon-px: null) {
  height: fourg-px-to-rem($px);
  width: fourg-px-to-rem($px);
  font-size: fourg-px-to-rem($px);

  @if ($icon-px) {
    .material-icons {
      font-size: fourg-px-to-rem($icon-px);
    }
  }
}

@mixin fourg-box-shadow($level: 1) {
  @if ($level == 1) {
    box-shadow: 
      0 2px 2px 0 rgba(0,0,0,0.07), 
      0 3px 1px -2px rgba(0,0,0,0.06), 
      0 1px 5px 0 rgba(0,0,0,0.1);
  } @else if ($level == 2) {
    box-shadow: 
      0 4px 5px 0 rgba(0,0,0,0.14), 
      0 1px 10px 0 rgba(0,0,0,0.12), 
      0 2px 4px -1px rgba(0,0,0,0.2);
  } @else {
    box-shadow: 
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

@mixin fourg-cover-background($position: center) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: $position;
}

@mixin fourg-link-button {
  @include fourg-theme(color, 'primary');
  @include fourg-transition(color);
  font: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  display: inline;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    @include fourg-theme(color, 'soft-on-surface');
    pointer-events: none;
    cursor: auto;
    text-decoration: none;
  }
}

@mixin fourg-ripple-base {
  @include fourg-transition(opacity background-color);
  @include fourg-overlay(absolute);
  content: '';
  display: block;
  pointer-events: none;
}

@mixin fourg-ripple($color: currentColor, $active-only: false) {
  position: relative;
  overflow: hidden;
  &::before {
    @include fourg-ripple-base;
    opacity: 0;
    @if ($color == currentColor) {
      background-color: currentColor;
    } @else {
      @include fourg-theme(background-color, $color);
    }
  }
  @if ($active-only == false) { 
    &:focus::before { opacity: $fourg-ripple--focus-opacity; }
    &:hover::before { opacity: $fourg-ripple--hover-opacity; }
  }
  &:active::before { opacity: $fourg-ripple--active-opacity; }
  &:disabled::before { display: none; }
}

@mixin fourg-table-row-ripple($color: currentColor, $active-only: false) {
  td {
    position: relative;
    overflow: hidden;
    &::before {
      @include fourg-ripple-base;
      opacity: 0;
      @if ($color == currentColor) {
        background-color: currentColor;
      } @else {
        @include fourg-theme(background-color, $color);
      }
    }
  }
  @if ($active-only == false) { 
    &:focus td::before { opacity: $fourg-ripple--focus-opacity; }
    &:hover td::before { opacity: $fourg-ripple--hover-opacity; }
  }
  &:active td::before { opacity: $fourg-ripple--active-opacity; }
  &:disabled td::before { display: none; }
}

@mixin fourg-static-ripple($color: currentColor, $style: 'focus') {
  position: relative;
  overflow: hidden;
  &::before {
    @include fourg-ripple-base;
    @if ($color == currentColor) {
      background-color: currentColor;
    } @else {
      @include fourg-theme(background-color, $color);
    }
    @if ($style == 'hover') {
      opacity: $fourg-ripple--hover-opacity;
    } @else if ($style == 'active') {
      opacity: $fourg-ripple--active-opacity;
    } @else {
      opacity: $fourg-ripple--focus-opacity;
    }
  }
}

@mixin fourg-table-row-static-ripple($color: currentColor, $style: 'focus') {
  td {
    position: relative;
    overflow: hidden;
    &::before {
      @include fourg-ripple-base;
      @if ($color == currentColor) {
        background-color: currentColor;
      } @else {
        @include fourg-theme(background-color, $color);
      }
      @if ($style == 'hover') {
        opacity: $fourg-ripple--hover-opacity;
      } @else if ($style == 'active') {
        opacity: $fourg-ripple--active-opacity;
      } @else {
        opacity: $fourg-ripple--focus-opacity;
      }
    }
  }
}

@mixin fourg-kill-ripple {
  &::before {
    opacity: 0 !important;
  }
}
