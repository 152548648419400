@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-filter-bar {
    position: relative;

    &__start {
        @include fourg-flex(row, stretch, flex-start);
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-box-shadow(2);
        @include fourg-flatten-children(true);
        position: relative;
        z-index: 9;
    }

    &__end {
        @include fourg-theme(border-color, 'border-on-background');
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        @include fourg-static-ripple(currentColor, 'focus');
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &__search {
        @include fourg-flex(row, center, flex-start);
        flex-grow: 1;
    }

		&__search-icon {
			@include fourg-transition(opacity);
			opacity: 1;
		}

		&--search-disabled &__search-icon {
			opacity: 0.4;
		}

    &__search-input {
			@include fourg-transition(opacity);
			@include fourg-gutter(padding, 0.5);
			font: inherit;
			appearance: none;
			border: none;
			box-shadow: none;
			outline: 0;
			box-sizing: border-box;
			font-size: inherit;
			color: inherit;
			line-height: inherit;
			width: 100%;
			opacity: 1;
    }

		&--search-disabled &__search-input {
			opacity: 0;
		}

    &__section {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-flatten-children(true);
        border-left-style: solid;
        border-left-width: 1px;

        > .fourg-action-toggle > .fourg-action-toggle__button,
        > .fourg-button {
            @include fourg-gutter(margin-left, 0.125);
            @include fourg-gutter(margin-right, 0.125);
            &:not(.fourg-button--active):not(:disabled) {
                color: inherit;
            }
        }
    }

    &__start > &__section:last-child {
        padding-right: 0;
    }

    &__actions {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-gutter(padding-left);
        @include fourg-flatten-children(true);
        border-left-style: solid;
        border-left-width: 1px;
    }

    &__filters {
        @include fourg-flex(row, center, flex-start);

        .fourg-chip-list {
            flex-grow: 1;
        }
    }
}
