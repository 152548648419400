@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-pagination {
    @include fourg-flex(row, center, flex-start);
    @include fourg-theme(border-color, 'border-on-surface');
    border-top-width: 1px;
    border-top-style: solid;
    width: 100%;

    &__page {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
    }

    &__limit {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        @include fourg-theme(border-color, 'border-on-background');
        border-left-width: 1px;
        border-right-width: 1px;
        border-left-style: solid;
        border-right-style: solid;
        flex-grow: 1;
    }

    &__links {
        @include fourg-flex(row, center, flex-end);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
    }

    &__label {
        @include fourg-flex(row, center, center);
    }

    &__label-text {
        @include fourg-theme(color, 'soft-on-background');
        font-size: fourg-px-to-rem(12px);
    }

    .fourg-select {
        margin-bottom: 0;
        font-size: fourg-px-to-rem(14px);
        margin: 0 fourg-px-to-rem(4px);

        &--disabled .fourg-select__ui-expand {
            @include fourg-theme(color, 'on-disabled');
        }
    }
}
