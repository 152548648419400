@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-grade-card {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-box-shadow(1);
    border-radius: 2px;
    position: relative;
		max-width: 100%;

    &--positive {

		}

    &--negative {
			
    }

    &--disabled {
        cursor: auto;
        pointer-events: none;
    }

		&__header {
			@include fourg-flex(row, flex-start, flex-start);
			flex-grow: 1;
			padding: 12px;
		}

    &__subtitle {
			@include fourg-theme(color, 'soft-on-surface');
			font-size: fourg-px-to-rem(12px);
			line-height: 1;
			margin: 6px 0 0;
			max-width: 100%;
    }

    &__title {
			@include fourg-flex(row, center, flex-start);
			font-size: fourg-px-to-rem(13px);
			line-height: 1.5;
			font-weight: 600;
			margin: 0;
			max-width: 100%;
			min-width: 0;
			display: block;
    }

		&__actions {
			margin-inline-end: -4px;
			margin-top: -4px;
			margin-inline-start: auto;

			.fourg-action-toggle__button {
				@include fourg-transition(opacity);
				color: inherit;
				opacity: 0;
			}
	}

	&:hover &__actions .fourg-action-toggle__button,
	&__actions.fourg-action-toggle--expanded .fourg-action-toggle__button,
	&__actions .fourg-action-toggle__button:focus,
	&__actions .fourg-action-toggle__button:active {
			opacity: 1;
	}

	&__metrics {
		font-size: fourg-px-to-rem(12px);
		font-weight: 600;
		padding: 8px;
	}

	&__metric-table {
		width: auto;
		max-width: 100%;
		border-collapse: collapse;
		position: relative;
		vertical-align: middle;
		text-align: unset;
	}

	&__metric-title {
		@include fourg-theme(color, 'soft-on-surface');
		font-weight: 700;
		text-transform: uppercase;
	}

	&__metric-value {

	}

	&__metric-comparison {
		@include fourg-theme(color, 'soft-on-surface');
	}

	&__metric-variance {
		font-weight: 700;
	}

	&--positive &__metric-variance {
		@include fourg-theme(color, 'success');
	}

	&--negative &__metric-variance {
		@include fourg-theme(color, 'error');
	}
}
