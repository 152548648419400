@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-tab-bar {
    flex-shrink: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &__content {
        @include fourg-flex(row, center, flex-start);
        position: relative;

        &::after,
        &::before {
            @include fourg-gutter(width, 0.5);
            @include fourg-gutter(height, 0.5);
            content: '';
            display: block;
            position: relative;
            flex-shrink: 0;
        }
    }

    &__tabs {
        @include fourg-flex(row, stretch, flex-start);
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__indicator {
        @include fourg-theme(background-color, 'primary');
        @include fourg-transition(left width);
        height: 3px;
        pointer-events: none;
        margin: 0;
        padding: 0;
        position: absolute;
        border: none;
        bottom: 0;
        left: 0;
        width: 0;
    }
}
