@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-action-list-item {

    &--disabled {
        cursor: auto;
        pointer-events: none;
    }

    &__action {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-flex(row, center, flex-start);
        @include fourg-ripple;
        padding-top: fourg-px-to-rem(10px);
        padding-bottom: fourg-px-to-rem(10px);
        padding-left: fourg-px-to-rem(14px);
        padding-right: fourg-px-to-rem(14px);
        font: inherit;
        cursor: pointer;
        appearance: none;
        background-color: transparent;
        color: inherit;
        box-sizing: border-box;
        border: none;
        margin: 0;
        width: 100%;
        text-align: inherit;
        outline: 0;
        line-height: 1.1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-shrink: 0;
    }

    &--active:not(&--disabled) &__action {
        @include fourg-static-ripple('primary', 'active');
    }

    &--disabled &__action {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
    }

    &__icon {
        // @include fourg-static-ripple('primary', 'active');
        @include fourg-icon-size(18px);
        margin-right: fourg-px-to-rem(10px);
        border-radius: 50%;
        overflow: hidden;
    }

    &__label {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        flex-grow: 1;
    }

    &__checkbox {
        margin: 0;
        pointer-events: none;
    }
}
