@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-share-task-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;
    }

    &__description {
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-gutter(margin-bottom);
        font-size: fourg-px-to-rem(14px);
    }

    &__form-field {
        @include fourg-gutter(padding-top);
        @include fourg-gutter(padding-bottom);
    }
}
