@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-password-lost-page {
	min-height: 100vh;

	.fourg-page-header {
		display: none;
	}
}
