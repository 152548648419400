@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-print-page {
	@include fourg-theme(background-color, 'surface');
	@include fourg-theme(color, 'on-surface');

	@media print {
		padding: 0;
	}

	&__header {
		@include fourg-flex(row, center, space-between);
		@include fourg-theme(border-bottom-color, 'border-on-surface');
		border-bottom-width: 4px;
		border-bottom-style: solid;
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	&__doc-info {
		text-align: end;
	}

	&__meta {
		opacity: 0.75;
		font-size: 14px;
		display: block;
		font-weight: 300;
	}

	&__title {
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 4px;
	}

	&__sections {
		@include fourg-flex(column, stretch, flex-start);
		gap: 20px;
	}

	&__section {
		@include fourg-flex(column, stretch, flex-start);
		min-width: 0;
		max-width: 100%;
		gap: 10px;
	}

	&__section-header {
		@include fourg-flex(row, center, flex-start);
	}

	&__section-title {
		font-size: 20px;
		font-weight: 300;
		margin: 0;
	}

	&__table {
		@include fourg-flex(row wrap, stretch, flex-start);
		@include fourg-theme(border-color, 'border-on-surface');
		border-width: 1px;
		border-style: solid;
		min-width: 0;
		max-width: 100%;
	}

	&__top {
		margin-bottom: 20px;

		@media print {
			display: none;
		}
	}

	&__logo {
		width: 80px;
	}

	.fourg-page-header {
		display: none;
	}
}

.fourg-task-print-page-info-node {
	@include fourg-flex(column, stretch, flex-start);
	@include fourg-theme(border-color, 'border-on-surface');
	border-width: 1px;
	border-style: solid;
	font-size: 14px;
	max-width: 100%;
	min-width: 0;
	box-sizing: border-box;
	padding: 8px 12px;

	&__label {
		font-size: 10px;
		margin-bottom: 4px;
		opacity: 0.64;
	}

	&__value {
		white-space: pre-wrap;
		word-wrap: break-word;
	}
}
