// root
$fourg-root--font-size: 16px;

// scrollbar
$fourg-scrollbar--size: 6px;

// background colors
$fourg-theme--background: #fafafa;
$fourg-theme--surface: #ffffff;
$fourg-theme--primary: #417CDE;
$fourg-theme--secondary: #021532;
$fourg-theme--error: #B00020;
$fourg-theme--warning: #E9A746;
$fourg-theme--success: #4CAF50;
$fourg-theme--highlight: #f4ee79;
$fourg-theme--disabled: #eeeeee;
$fourg-theme--registration: #000000;
// text colors
$fourg-theme--on-background: #4f4f4f;
$fourg-theme--on-surface: #4a4a4a;
$fourg-theme--on-primary: #ffffff;
$fourg-theme--on-secondary: #ffffff;
$fourg-theme--on-error: #ffffff;
$fourg-theme--on-warning: #ffffff;
$fourg-theme--on-success: #ffffff;
$fourg-theme--on-highlight: #000000;
$fourg-theme--on-disabled: #aaaaaa;
$fourg-theme--on-registration: #ffffff;
// soft colors
$fourg-theme--soft-on-background: rgba($fourg-theme--on-background, 0.5);
$fourg-theme--soft-on-surface: rgba($fourg-theme--on-surface, 0.5);
$fourg-theme--soft-on-primary: rgba($fourg-theme--on-primary, 0.64);
$fourg-theme--soft-on-secondary: rgba($fourg-theme--on-secondary, 0.5);
$fourg-theme--soft-on-error: rgba($fourg-theme--on-error, 0.64);
$fourg-theme--soft-on-warning: rgba($fourg-theme--on-warning, 0.64);
$fourg-theme--soft-on-success: rgba($fourg-theme--on-success, 0.64);
$fourg-theme--soft-on-highlight: rgba($fourg-theme--on-highlight, 0.64);
$fourg-theme--soft-on-disabled: rgba($fourg-theme--on-disabled, 0.5);
$fourg-theme--soft-on-registration: rgba($fourg-theme--on-registration, 0.5);
// border colors
$fourg-theme--border-on-background: rgba($fourg-theme--on-background, 0.15);
$fourg-theme--border-on-surface: rgba($fourg-theme--on-surface, 0.15);
$fourg-theme--border-on-primary: rgba($fourg-theme--on-primary, 0.15);
$fourg-theme--border-on-secondary: rgba($fourg-theme--on-secondary, 0.15);
$fourg-theme--border-on-error: rgba($fourg-theme--on-error, 0.15);
$fourg-theme--border-on-warning: rgba($fourg-theme--on-warning, 0.15);
$fourg-theme--border-on-success: rgba($fourg-theme--on-success, 0.15);
$fourg-theme--border-on-highlight: rgba($fourg-theme--on-highlight, 0.15);
$fourg-theme--border-on-disabled: rgba($fourg-theme--on-disabled, 0.15);
$fourg-theme--border-on-registration: rgba($fourg-theme--on-registration, 0.15);
// theme map
$fourg-theme--map: (
  'primary': $fourg-theme--primary,
  'secondary': $fourg-theme--secondary,
  'error': $fourg-theme--error,
  'warning': $fourg-theme--warning,
  'success': $fourg-theme--success,
  'highlight': $fourg-theme--highlight,
  'disabled': $fourg-theme--disabled,
  'background': $fourg-theme--background,
  'surface': $fourg-theme--surface,
  'registration': $fourg-theme--registration,
  'on-primary': $fourg-theme--on-primary,
  'on-secondary': $fourg-theme--on-secondary,
  'on-error': $fourg-theme--on-error,
  'on-warning': $fourg-theme--on-warning,
  'on-success': $fourg-theme--on-success,
  'on-highlight': $fourg-theme--on-highlight,
  'on-disabled': $fourg-theme--on-disabled,
  'on-background': $fourg-theme--on-background,
  'on-surface': $fourg-theme--on-surface,
  'on-registration': $fourg-theme--on-registration,
  'soft-on-primary': $fourg-theme--soft-on-primary,
  'soft-on-secondary': $fourg-theme--soft-on-secondary,
  'soft-on-error': $fourg-theme--soft-on-error,
  'soft-on-warning': $fourg-theme--soft-on-warning,
  'soft-on-success': $fourg-theme--soft-on-success,
  'soft-on-highlight': $fourg-theme--soft-on-highlight,
  'soft-on-disabled': $fourg-theme--soft-on-disabled,
  'soft-on-background': $fourg-theme--soft-on-background,
  'soft-on-surface': $fourg-theme--soft-on-surface,
  'soft-on-registration': $fourg-theme--soft-on-registration,
  'border-on-primary': $fourg-theme--border-on-primary,
  'border-on-secondary': $fourg-theme--border-on-secondary,
  'border-on-error': $fourg-theme--border-on-error,
  'border-on-warning': $fourg-theme--border-on-warning,
  'border-on-success': $fourg-theme--border-on-success,
  'border-on-highlight': $fourg-theme--border-on-highlight,
  'border-on-disabled': $fourg-theme--border-on-disabled,
  'border-on-background': $fourg-theme--border-on-background,
  'border-on-surface': $fourg-theme--border-on-surface,
  'border-on-registration': $fourg-theme--border-on-registration,
);

// gutter
$fourg-gutter--phone: 16px;
$fourg-gutter--tablet: 18px;
$fourg-gutter--laptop: 20px;
$fourg-gutter--desktop: 22px;

// respond
$fourg-respond--phone-max: 303px;
$fourg-respond--tablet-min: 304px;
$fourg-respond--tablet-max: 767px;
$fourg-respond--laptop-min: 768px;
$fourg-respond--laptop-max: 1279px;
$fourg-respond--desktop-min: 1280px;

// respond map
$fourg-respond--map: (
  'phone': (
    'max': $fourg-respond--phone-max,
  ),
  'tablet': (
    'min': $fourg-respond--tablet-min,
    'max': $fourg-respond--tablet-max,
  ),
  'laptop': (
    'min': $fourg-respond--laptop-min,
    'max': $fourg-respond--laptop-max,
  ),
  'desktop': (
    'min': $fourg-respond--desktop-min,
  ),
	'mobile': (
    'max': $fourg-respond--tablet-max,
  ),
	'non-mobile': (
		'min': $fourg-respond--laptop-min,
	),
);

// ripple
$fourg-ripple--focus-opacity: 0.05;
$fourg-ripple--hover-opacity: 0.1;
$fourg-ripple--active-opacity: 0.15;
