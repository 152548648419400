@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-sign-in-page {
	min-height: 100vh;

	&__form-bottom {

		a {
			font-size: fourg-px-to-rem(14px);
		}

		.fourg-form-field__input {
			@include fourg-flex(row wrap, center, space-between);
		}

		.fourg-checkbox {
			margin-bottom: 0;
		}
	}

	.fourg-page-header {
		display: none;
	}
}
