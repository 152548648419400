@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-menu {

    &__menu {
        @include fourg-flatten-children;
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
