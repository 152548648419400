@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-user-card {
    @include fourg-flex(row, center, flex-start);

    &__avatar {
        @include fourg-icon-size(32px);
        border-radius: 50%;
        overflow: hidden;
    }

    &__name {
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        font-size: fourg-px-to-rem(14px);
        font-weight: 600;
        margin-bottom: 0;
        flex-grow: 1;
    }

    &__remove {
        @include fourg-transition(opacity);
        color: inherit;
        opacity: 0;
    }

    &:hover &__remove,
    &:active &__remove,
    &:focus &__remove {
        opacity: 1;
    }
}
