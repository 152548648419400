@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-breadcrumbs {
    font: inherit;

    &__items {
        @include fourg-flex(row, center, flex-start);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        @include fourg-inline-flex(row, center, flex-start);
    }

    &__action {
        color: inherit;
        display: block;
    }

    &__sep {
        @include fourg-gutter(margin-left, 0.125);
        @include fourg-gutter(margin-right, 0.125);
        @include fourg-icon-size(16px);
        opacity: 0.56;
    }
}
