@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-field {
    @include fourg-gutter(padding, 0.5);
    box-sizing: border-box;
    width: 100%;
		position: relative;

    &--size-xsmall {
        width: 25%;
    }

    &--size-small {
        width: 33.33333%;
    }

    &--size-medium {
        width: 50%;
    }

    &--hidden {
        @include fourg-screen-reader-only;
    }

    &__input {
        @include fourg-flatten-children;
				position: relative;
    }

    &__label {
        @include fourg-gutter(margin-bottom, 1.25);
        font-size: fourg-px-to-rem(24px);
        font-weight: 600;
				position: relative;
    }
}
