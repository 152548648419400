@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-reset-password-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;

        .fourg-form__action--delete {

            &:not(:disabled) {
                @include fourg-theme(background-color, 'primary');
            }
        }
    }

    &__description {
        @include fourg-theme(color, 'soft-on-surface');
        font-size: fourg-px-to-rem(14px);
    }

    &__loader {
        border-radius: 0 0 8px 8px;
    }

    &__complete-message {

        .fourg-icon-message__message {
            @include fourg-gutter(margin-top);
            @include fourg-flatten-children(true);
            
            .fourg-button {
                @include fourg-gutter(margin-left, 0.25);
                @include fourg-gutter(margin-right, 0.25);
            }
        }
    }
}
