@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-comment-card {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-flex(row, flex-start, flex-start);
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		@include fourg-gutter(padding-top, 0.5);
		@include fourg-gutter(padding-bottom);
		box-sizing: border-box;
		min-width: 0;
		outline: 0;
		position: relative;

		&::before {
			@include fourg-transition(opacity);
			@include fourg-overlay(absolute);
			content: '';
			background-color: currentColor;
			opacity: 0;
		}
		
		&:hover::before {
			opacity: 0.04;
		}

		&:focus-visible::before {
			opacity: 0.06;
		}

    &__avatar {
        @include fourg-theme(color, 'primary');
        @include fourg-static-ripple('primary', 'active');
        @include fourg-icon-size(32px, 20px);
        flex-shrink: 0;
        margin-right: fourg-px-to-rem(8px);
        border-radius: 50%;
        overflow: hidden;
				position: relative;
    }

    &__header {
        padding: fourg-px-to-rem(7px) 0;
        display: block;
        position: relative;
    }

    &__creator {
        font-size: fourg-px-to-rem(14px);
        line-height: 1;
        font-weight: 700;
        margin-bottom: 0;
        vertical-align: baseline;
        display: inline-block;
    }

    &__date {
        @include fourg-theme(color, 'soft-on-surface');
        padding: 0 fourg-px-to-rem(6px);
        font-size: fourg-px-to-rem(12px);
        vertical-align: baseline;
        display: inline;
    }

    &__content {
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
				position: relative;
    }

    &__body {
        @include fourg-flatten-children;
        font-size: fourg-px-to-rem(14px);
        word-wrap: break-word;

        strong {
            @include fourg-theme(color, 'primary');
            font-weight: 600;
        }
    }
    
    &__attachment {
        @include fourg-theme(border-color, 'primary');
        @include fourg-gutter(padding-left, 0.75);
        border-left-style: solid;
        border-left-width: 4px;
    }

    &__attachment + &__attachment {
        @include fourg-gutter(margin-top);
    }

    &__subtask {
        max-width: 300px;
    }

    &__reminder {
        max-width: 300px;
    }

		&__grade {
			width: 300px;
		}

		&__expense {
			width: 300px;
		}

    &__info {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-gutter(margin-top, 0.4);
        gap: 4px;
				position: relative;

        .fourg-icon {
            @include fourg-icon-size(14px);
        }
        
        p {
            font-size: fourg-px-to-rem(10px);
            margin: 0;
        }
    }

    &__form {

        .fourg-form__fields {
            padding: 0;
        }

        .fourg-form-field {
            padding: 0;
        }

        .fourg-form__actions {
            @include fourg-flex(row-reverse, center, flex-end);
            @include fourg-gutter(padding-top, 0.5);
            @include fourg-gutter(gap, 0.5);
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }

        .fourg-form__action--cancel {
            margin: 0;
        }

        .fourg-editor {
        
            .DraftEditor-root {
                padding: 0;
            }
        }
    }
}
