@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-input-description {
    @include fourg-theme(color, 'on-surface');
    @include fourg-flatten-children;
    @include fourg-gutter(padding-left, 0.75);
    @include fourg-gutter(padding-right, 0.75);
    @include fourg-gutter(margin-top, 0.4);
    @include fourg-transition(color);
    font-size: fourg-px-to-rem(12px);
    box-sizing: border-box;

    &--disabled {
        @include fourg-theme(color, 'soft-on-surface');
    }
}
