@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-lookup-multiple {
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;

    &__header {
        position: relative;
    }

    &__search-container {
        position: relative;
    }

    &__loader {
        @include fourg-theme(color, 'primary');
        position: absolute;
        top: fourg-px-to-rem(14px);
        right: fourg-px-to-rem(14px);
    }

    &--variant-quiet &__loader {
        top: fourg-px-to-rem(7px);
        right: fourg-px-to-rem(6px);
    }

    &__select {

        .fourg-select-multiple__ui-actions {
            max-height: 50vh;
            overflow-y: auto;
        }
    }
}
