@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-board-handle {
    @include fourg-flex(column, stretch, flex-start);
    margin-bottom: 8px;
    position: relative;
    box-sizing: border-box;

    &--dragging {
        z-index: 8 !important;
    }

    &--dropping {
        transition-duration: 1ms !important;
    }
}
