@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-recurrence-input {

    &__interval {
        margin-bottom: 0;

        .fourg-select__ui-expand {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__aux {
        @include fourg-gutter(padding);
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'soft-on-surface');
        @include fourg-flatten-children;
        border-style: solid;
        border-width: 1px;
        border-radius: 0 0 3px 3px;
        border-top: none;
    }

    &--disabled &__aux {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
    }

    &__date {
        margin: 0;
    }

    &__week-day {
        @include fourg-gutter(margin-bottom);
        @include fourg-flatten-children;

        .fourg-chip-list {
            @include fourg-gutter(margin-bottom);
        }
    }

    &__month-day {
        @include fourg-gutter(margin-bottom);
    }

    &__remove-recurrence,
    &__add-recurrence {
        @include fourg-link-button;
    }
}
