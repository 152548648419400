@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-section {
    width: 100%;

    &--hidden {
        @include fourg-screen-reader-only;
    }

    &__fields {
        @include fourg-flex(row wrap, stretch, flex-start);
    }
}
