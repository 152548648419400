@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-user-form-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;
    }

    &__loader {
        border-radius: 0 0 8px 8px;
    }

    &__section {

        &--profile-image {
            width: 32%;
        }

        &--edit-profile {
            flex-grow: 1;
            width: 68%;
        }
    }

    .fourg-form__sections {
        @include fourg-flex(row wrap, stretch, flex-start);
    }

    .fourg-dialog__surface {
        max-width: 984px;
    }
}
