@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-info-by-field {
    @include fourg-gutter(padding, 0.5);
    box-sizing: border-box;
    width: 100%;

		&--size-xsmall {
			width: 25%;
		}

		&--size-small {
			width: 33.33333%;
		}

		&--size-medium {
			width: 50%;
		}

    &__label {
        @include fourg-theme(color, 'on-background');
        @include fourg-gutter(margin-bottom, 0.25);
        font-size: fourg-px-to-rem(14px);
        display: block;
    }

    &--variant-quiet &__label {
        @include fourg-theme(color, 'soft-on-background');
        font-size: fourg-px-to-rem(12px);
    }

    &__content {
        @include fourg-gutter(margin-bottom, 0.25);
        display: block;
        word-wrap: break-word;
    }

    &--variant-quiet &__content {
        font-size: fourg-px-to-rem(14px);
    }

    &--type-checkbox {

        .fourg-icon {
            @include fourg-icon-size(16px);
        }
    }
}
