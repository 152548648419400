@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-file-input {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-ripple('primary');
    border-style: dashed;
    border-width: 2px;
    border-radius: 4px;
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    // box-sizing: border-box;

    &--variant-mini {
        padding-top: 100%;
    }

    &:hover,
    &:active,
    &:focus {
        @include fourg-theme(border-color, 'primary');
    }

    &--disabled {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
        @include fourg-kill-ripple;
        cursor: auto;
        pointer-events: none;
    }

    &__label {
        @include fourg-screen-reader-only;
    }

    &__input {
        @include fourg-overlay(absolute);
        opacity: 0;
        cursor: pointer;
    }

    &__ui {
        @include fourg-gutter(padding);
        @include fourg-flex(column, center, center);
        @include fourg-overlay(absolute);
        @include fourg-flatten-children;
        pointer-events: none;
        box-sizing: border-box;
    }

    &__icon {
        @include fourg-theme(color, 'primary');
        @include fourg-icon-size(64px);
        @include fourg-gutter(margin-bottom);
    }

    &--variant-mini &__icon {
        @include fourg-overlay(absolute);
    }

    &--disabled &__icon {
        @include fourg-theme(color, 'soft-on-disabled');
    }

    &__heading {
        @include fourg-gutter(margin-bottom, 0.25);
        font-size: fourg-px-to-rem(18px);
        text-align: center;
    }

    &__description {
        @include fourg-theme(color, 'soft-on-surface');
        font-size: fourg-px-to-rem(14px);
        text-align: center;
    }

    &--variant-mini &__heading,
    &--variant-mini &__description {
        display: none;
    }

    &--disabled &__description {
        @include fourg-theme(color, 'soft-on-disabled');
    }
}
