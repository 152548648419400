@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-dialog {
    @include fourg-overlay(fixed);
    @include fourg-flex(column, center, center);
    z-index: 11;
    pointer-events: none;

    &--open {
        pointer-events: inherit;
    }

    &__backdrop {
        @include fourg-overlay(absolute);
        @include fourg-theme(background-color, 'registration');
        @include fourg-transition(opacity);
        opacity: 0;
    }

    &--open > &__backdrop {
        opacity: 0.5;
    }

    &__surface {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-box-shadow;
        @include fourg-transition(opacity transform);
        // transform: scale(0.95);
        width: 90%;
        max-height: 90%;
        max-width: 640px;
        position: relative;
        opacity: 0;
        border-radius: 8px;
    }

    &--open > &__surface {
        opacity: 1;
        // transform: scale(1);
    }

    &--size-small > &__surface {
        max-width: 320px;
    }
    
    &--size-large > &__surface {
        max-width: 1024px;
    }

    &__title {
        font-size: fourg-px-to-rem(20px);
        margin-bottom: 0;
        font-weight: 600;
    }

    &__header {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding);
        @include fourg-theme(border-color, 'border-on-surface');
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }

    &__close {
        @include fourg-inline-flex(row, center, center);
        @include fourg-ripple;
        cursor: pointer;
        font: inherit;
        appearance: none;
        margin-left: auto;
        border: none;
        box-shadow: none;
        background-color: transparent;
        font-size: fourg-px-to-rem(32px);
        width: fourg-px-to-rem(32px);
        height: fourg-px-to-rem(32px);
        border-radius: 3px;
        outline: 0;

        &:disabled {
            cursor: auto;
            pointer-events: none;
        }
    }

    &__content {
        @include fourg-flex(column, stretch, flex-start);
        position: relative;
        flex-grow: 1;
        overflow-y: hidden;
    }

    .fourg-form {
        border: none;
        border-radius: 0 0 8px 8px;
        max-height: 100%;
				min-height: 0;

        &__fields {
            overflow-y: auto;
        }
    }
}
