@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-lookup-dialog {

    .fourg-dialog__surface {
        height: 80vh;
    }

    &__form {
        @include fourg-flex(column, stretch, flex-start);
        overflow: hidden;
        border: none;
        border-radius: 0 0 8px 8px;
        padding: 0;
        height: 100%;

        .fourg-form__fields {
            padding: 0;
            flex-grow: 1;
        }

        .fourg-form-field {
            padding: 0;
        }

        .fourg-form-field__input {
            height: 100%;
        }
    }

    &__lookup-multiple {
        @include fourg-flex(column, stretch, flex-start);
        height: 100%;
        margin-bottom: 0;

        .fourg-select-multiple__ui-label {
            display: none;
        }

        .fourg-lookup-multiple__header {
            @include fourg-theme(background-color, 'background');
            @include fourg-theme(border-bottom-color, 'border-on-background');
            @include fourg-gutter(padding);
            box-sizing: border-box;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            
            .fourg-input {
                margin-bottom: 0;
            }
        }

        .fourg-lookup-multiple__select {
            margin-bottom: 0;
            flex-grow: 1;
        }

        .fourg-select-multiple__ui {
            @include fourg-flex(column, stretch, flex-start);
            height: 100%;
        }

        .fourg-select-multiple__ui-actions {
            @include fourg-overlay(absolute);
            box-shadow: none;
            max-height: unset;
        }

        .fourg-action-list-item__action {
            @include fourg-gutter(padding-left);
            @include fourg-gutter(padding-right);
        }

        .fourg-input__label {
            @include fourg-screen-reader-only;
        }
    }
}
