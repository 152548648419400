@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;
    }

    &__loader {
        border-radius: 0 0 8px 8px;
    }
}
