@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-card {
	@include fourg-flex(column, stretch, flex-start);
	@include fourg-theme(background-color, 'surface');
	@include fourg-theme(color, 'on-surface');
	@include fourg-theme(border-color, 'border-on-surface');
	@include fourg-box-shadow(1);
	border-radius: 2px;
	position: relative;
	cursor: pointer;

	&--error {
		@include fourg-static-ripple('error', 'focus');
		@include fourg-theme(color, 'error');
		@include fourg-theme(outline-color, 'error');
		outline-style: solid;
		outline-width: 2px;
	}

	&--active {
		@include fourg-theme(outline-color, 'primary');
		outline-style: solid;
		outline-width: 2px;
	}

	&--variant-mini {
		@include fourg-theme(color, 'on-background');
		@include fourg-theme(border-color, 'border-on-background');
		background-color: transparent;
		box-shadow: none;
	}

	&--disabled {
		cursor: auto;
		pointer-events: none;
	}

	&__end {
		@include fourg-flex(column, flex-end, flex-start);
	}

	&__end &__badge--status {
		margin-bottom: 6px;
	}

	&__actions {
		margin-right: -4px;
		margin-top: -4px;

		.fourg-action-toggle__button {
			@include fourg-transition(opacity);
			color: inherit;
			opacity: 0;
		}
	}

	&__actions.fourg-action-toggle--expanded .fourg-action-toggle__button,
	&:hover &__actions .fourg-action-toggle__button,
	&__actions .fourg-action-toggle__button:focus,
	&__actions .fourg-action-toggle__button:active {
		opacity: 1;
	}

	&__header {
		@include fourg-flex(row, flex-start, flex-start);
		flex-grow: 1;
		padding: 12px;
	}

	&__header-content {
		flex-grow: 1;
		min-width: 0;
	}

	&--variant-mini:not(&--error) &__header {
		padding: 0;
	}

	&__badge {
		margin-left: auto;
	}

	&__subtitle {
		@include fourg-theme(color, 'soft-on-surface');
		font-size: fourg-px-to-rem(12px);
		line-height: 1;
		margin: 6px 0 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	&__title {
		@include fourg-flex(row, center, flex-start);
		font-size: fourg-px-to-rem(13px);
		line-height: 1.5;
		font-weight: 600;
		margin: 0;
		max-width: 100%;
		min-width: 0;
	}

	&__title-text {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__subtask-icon {
		@include fourg-icon-size(18px);
		@include fourg-theme(color, 'soft-on-surface');
		margin-right: 0.05em;
		margin-top: -0.2em;
	}

	&__archived-icon {
		@include fourg-icon-size(18px);
		@include fourg-theme(color, 'soft-on-surface');
		margin-left: 0.25em;
	}

	&__footer {
		@include fourg-flex(row, center, flex-start);
		padding: 8px 10px;
		margin-top: 28px;
	}

	&--variant-mini &__footer {
		padding: 0;
	}

	&--following &__follow {

		.fourg-button__icon {
			@include fourg-theme(color, 'primary');
		}
	}

	&__date {
		font-size: fourg-px-to-rem(12px);
		line-height: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		font-weight: 600;
	}

	&__avatar + &__date {
		margin-left: 8px;
	}

	&--variant-mini &__date {
		@include fourg-theme(color, 'soft-on-background');
	}

	&__avatar {
		border-radius: 50%;
		overflow: hidden;
	}

	&__warnings {
		@include fourg-flex(row, center, flex-start);
		@include fourg-theme(background-color, 'error');
		@include fourg-theme(color, 'on-error');
		padding: 4px 12px;
		gap: 8px;
		margin: 0;
		font-size: fourg-px-to-rem(10px);
		font-weight: 400;
		list-style: none;
		cursor: help;

		.fourg-icon {
			@include fourg-icon-size(18px);
			flex-shrink: 0;
			pointer-events: none;
		}

		p {
			flex-grow: 1;
			margin: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			min-width: 0;
			padding-top: 0.25em;
		}
	}

	&__radio {
		@include fourg-screen-reader-only;
	}
}
