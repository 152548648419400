@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-action-value-by-field {
    
    address {
        display: inline;
    }
}
