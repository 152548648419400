@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-menu-item {
    @include fourg-gutter(margin-top, 0.25);
    @include fourg-gutter(margin-bottom, 0.25);
    position: relative;

    &--disabled {
        // @include fourg-theme(background-color, 'disabled');
        // @include fourg-theme(color, 'on-disabled');
        // @include fourg-theme(border-color, 'border-on-disabled');
        pointer-events: none;
        cursor: auto;
    }

    &__action {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        font: inherit;
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        color: inherit;
        width: 100%;
        border: 0;
        margin: 0;
        box-sizing: border-box;
        text-decoration: none;
        outline: 0;
        border-radius: 3px;
        overflow: hidden;
        position: relative;

        &:not(span) {
            cursor: pointer;
            appearance: none;
            text-align: unset;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &:not(&--active) &__action:not(span) {
        @include fourg-ripple;
    }

    &--active &__action {
        @include fourg-static-ripple('primary', 'active');
    }

    &__content {
        @include fourg-flex(row, center, flex-start);
    }

    &__icon {
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        @include fourg-transition(color fill);
        @include fourg-icon-size(20px);
        position: relative;
    }

    &--active &__icon {
        @include fourg-theme(color, 'primary');
    }

    &__label {
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        position: relative;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        white-space: nowrap;
        font-size: 0.86rem;
        flex-grow: 1;
    }

    &__count {
        @include fourg-gutter(margin-right, 0.5);
    }
}
