@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-header {
	@include fourg-flex(column, stretch, flex-start);
	@include fourg-theme(background-color, 'secondary');
	@include fourg-theme(color, 'on-secondary');
	@include fourg-theme(border-color, 'border-on-secondary');
	position: relative;
	z-index: 10;
	box-sizing: border-box;
	flex-shrink: 0;
	overflow-y: auto;

	@media print { 
		display: none;
	}

	&__branding {
		@include fourg-flex(column, stretch, flex-start);
		@include fourg-gutter(padding-top);
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		box-sizing: border-box;
	}

	&__navigation {
		@include fourg-flex(column, stretch, flex-start);
		@include fourg-gutter(padding);
		box-sizing: border-box;
		flex-grow: 1;

		.fourg-menu {
			@include fourg-theme(border-color, 'border-on-secondary');
			border-top-width: 1px;
			border-top-style: solid;
		}
	}

	&__branding-menu {


		.fourg-menu__menu {
			padding: 0;
		}
	}

	&__notifications {

		&--dirty .fourg-menu-item__icon {
			position: relative;

			&::after {
				@include fourg-theme(background-color, 'error');
				@include fourg-theme(border-color, 'secondary');
				content: '';
				border-width: 2px;
				border-style: solid;
				width: 8px;
				height: 8px;
				position: absolute;
				top: -8px;
				right: 2px;
				border-radius: 8px;
			}
		}
	}

	&__logo-link {
		font-weight: 600;

		.fourg-menu-item__action {
			@include fourg-kill-ripple;
		}
	}

	&__account-menu {
		margin-top: auto;

		.fourg-menu__menu {
			padding-bottom: 0;
		}
	}

	.fourg-menu-item__icon {
		@include fourg-theme(color, 'soft-on-secondary');
	}

	.fourg-menu-item--active .fourg-menu-item__icon {
		@include fourg-theme(color, 'highlight');
	}

	.fourg-menu-item__content {
		@include fourg-transition(width padding opacity);
	}

	&:not(&--expanded) .fourg-menu-item__content {
		width: 0;
		padding-right: 0;
		padding-left: 0;
		opacity: 0;
	}

	&--expanded .fourg-menu-item__content {
		width: 200px;
		opacity: 1;
	}
}
