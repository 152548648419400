@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-dashboard-page {

	&__content {
		@include fourg-flex(row, stretch, flex-start);
		flex-grow: 1;
	}

	&__data {
		@include fourg-flex(column, stretch, flex-start);
		flex-grow: 1;
		width: 100%;
		min-width: 0;
	}

	&__sidebars {
		@include fourg-flex(row, stretch, flex-start);
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		@include fourg-theme(border-color, 'border-on-surface');
		position: relative;
		border-right-width: 0;
		border-left-width: 1px;
		border-left-style: solid;
		width: 100%;
		max-width: 360px;
		z-index: 8;
	}

	.fourg-page__content {
		padding: 0;
	}

	.fourg-filter-bar {

		&__start {
			box-shadow: none;
			border-top-width: 1px;
			border-top-style: solid;
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}
	}

	.fourg-table {

		&__th {
			@include fourg-theme(background-color, 'surface');
			@include fourg-theme(color, 'on-surface');
			@include fourg-theme(border-color, 'border-on-surface');
		}
	}
}
