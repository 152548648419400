@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-lookup-resource-section-input-new {
	@include fourg-gutter(margin-bottom);
	position: relative;
	width: 100%;

	.fourg-select-input {
		@include fourg-gutter(margin-bottom, 0);
	}

	&__add-button {
		@include fourg-link-button;
	}
}
