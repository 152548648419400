@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-comments {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-flex(column, stretch, flex-start);
    position: relative;
    flex-grow: 1;
    min-width: 0;

    &__start {
        @include fourg-flex(row, center, center);
        @include fourg-flatten-children(true);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;

        .fourg-loader-pill {
            @include fourg-gutter(margin, 0.5);
        }
    }

    &__surface {
        position: relative;
        flex-grow: 1;
    }

    &__scroller {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-overlay(absolute);
        overflow-y: auto;
    }

    &__cards {
        margin: auto 0 0;
    }

    &__end {
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-bottom);
    }

    &__form {
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        border-radius: 0;
    }

    &__editor {

        .fourg-input__label {
            display: none;
        }

        .fourg-input__input {
            height: 80px;
            min-height: 80px;
            max-height: 80px;
        }
    }
}
