@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-sidebar-section-actions {
    @include fourg-flex(row, center, flex-start);
    @include fourg-gutter(padding);
    @include fourg-gutter(gap, 0.5);
    box-sizing: border-box;
    width: 100%;
}
