@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-variance-span {
	@include fourg-inline-flex(row, center, center);
	@include fourg-gutter(gap, 0.32);
	max-width: 100%;
	position: relative;

	&--positive {
		@include fourg-theme(color, 'success');
	}

	&--negative {
		@include fourg-theme(color, 'error');
	}

	&__label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
	}

	.fourg-icon {
		flex-shrink: 0;
		font-size: inherit;
		width: 1em;
		height: 1em;
		line-height: 1em;

		> i {
			transform: scale(1.25);
		}
	}
}
