@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-page-header {
	@include fourg-flex(row, stretch, flex-start);
	@include fourg-theme(background-color, 'primary');
	@include fourg-theme(color, 'on-primary');
	@include fourg-theme(border-color, 'border-on-primary');
	@include fourg-gutter(padding);
	position: relative;
	z-index: 10;

	&__content {
		flex-grow: 1;
		box-sizing: border-box;
		max-width: 100%;
	}

	&__actions {
		@include fourg-flex(row, center, flex-end);
		@include fourg-gutter(gap, 0.25);
		box-sizing: border-box;
		flex-shrink: 0;
		max-width: 100%;
	}

	&__topline {
		@include fourg-gutter(margin-bottom, 0.5);
		text-transform: uppercase;
		font-size: fourg-px-to-rem(12px);
		font-weight: 600;
		line-height: 1.5;
		display: block;
	}

	&__title {
		@include fourg-gutter(margin-bottom, 0.5);
		font-size: fourg-px-to-rem(24px);
		font-weight: 600;
		word-wrap: break-word;
		min-width: 0;
	}

	&__description-container {
		@include fourg-flatten-children;
		@include fourg-theme(color, 'soft-on-primary');
		font-size: fourg-px-to-rem(12px);
		line-height: 1.25;
	}
}
