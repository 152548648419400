@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-button {
    @include fourg-inline-flex(row, center, center);
    @include fourg-theme(color, 'primary');
    @include fourg-theme(border-color, 'primary');
    @include fourg-transition(color background-color border-color box-shadow);
    @include fourg-ripple;
    font: inherit;
    background-color: transparent;
    appearance: none;
    font-size: fourg-px-to-rem(14px);
    min-width: 72px;
    max-width: 100%;
    padding: 6px 16px;
    margin: 0;
    border: none;
    flex-shrink: 0;
    font-weight: 600;
    border-radius: 3px;
    outline: 0;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
    &--icon-only {
        min-width: unset;
        padding-right: 0;
    }

    &--icon-only.fourg-button--variant-mini {
        padding-left: 0;
        padding-right: 0;
    }

    &--active {
        @include fourg-static-ripple('primary', 'active');
    }

    &:disabled {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
        @include fourg-kill-ripple;
        cursor: auto;
        pointer-events: none;
    }

    &--variant-raised {
        @include fourg-theme(background-color, 'primary');
        @include fourg-theme(color, 'on-primary');
    }

    &--variant-outlined {
        border-width: 1px;
        border-style: solid;

        &:disabled {
            background-color: transparent;
        }
    }

    &--variant-mini {
        font-size: fourg-px-to-rem(11px);
        min-width: unset;
        padding: 4px 6px;

        &:disabled {
            background-color: transparent;
        }
    }

    &--has-icon {
        padding-left: 0;
    }

    &__icon {
        @include fourg-icon-size(18px);
        padding: 0 8px;
    }

    &--variant-mini &__icon {
        @include fourg-icon-size(15px);
        padding: 0 2px;
    }

    &__label {
        display: block;
        flex-grow: 1;
        line-height: 1.5;
    }

    &--icon-only &__label {
        @include fourg-screen-reader-only;
    }
}
