@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-report-ship-date-cell {
	@include fourg-flex(column, flex-start, flex-start);
	@include fourg-gutter(gap, 0.1);
	min-width: 0;

	> * {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
		max-width: 100%;
	}

	code {
		@include fourg-theme(color, 'primary');
	}

	small {
		font-weight: 400;
		opacity: 0.64;
	}

	.fourg-table-cell-by-field {
		display: inline;
	}
}
