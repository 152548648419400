@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-layout {
    @include fourg-flex(row, stretch, flex-start);
    @include fourg-overlay(fixed);

		&--body-scrollable {
			height: auto;
			position: relative;
		}

    &__content {
        @include fourg-flex(column, stretch, flex-start);
        flex-grow: 1;
        position: relative;
        min-width: 0; // stop flex from infering min width.
    }
}
