@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-company-page {

	.fourg-page__content {
		@include fourg-flex(row, stretch, flex-start);
		padding: 0;
	}

	&__header-description {
		display: block;
	}

	&__header-last-active {
		display: inline;

		time {
			display: inline;
			padding: 0 fourg-px-to-rem(4px);
		}
	}

	&__aux {
		@include fourg-flex(column, stretch, flex-start);
		position: relative;
		flex-grow: 1;
		min-width: 0;
	}

	&__attachments {
		@include fourg-flex(column, stretch, flex-start);
		flex-grow: 1;
		position: relative;

		.fourg-card-list {
			@include fourg-overlay(absolute);
			overflow-y: auto;
		}

		.fourg-loader {
			z-index: 0;
			background-color: transparent;
		}
	}

	&__delete-confirm-dialog {

		.fourg-icon-message__icon {
			@include fourg-theme(color, 'error');
		}
	}

	.fourg-filter-bar {

		&__start {
			box-shadow: none;
			border-top-width: 1px;
			border-top-style: solid;
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}
	}

	.fourg-table {

		&__th {
			@include fourg-theme(background-color, 'surface');
			@include fourg-theme(color, 'on-surface');
			@include fourg-theme(border-color, 'border-on-surface');
		}
	}
}
