@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-actions {
    @include fourg-flex(row, center, flex-start);
    @include fourg-gutter(padding);
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-flatten-children(true);
    border-top-width: 1px;
    border-top-style: solid;
  
    &__action {
        @include fourg-gutter(margin-right, 0.5);

        &--cancel {
            @include fourg-theme(color, 'error');
            @include fourg-theme(border-color, 'error');
            margin-right: auto;
        }

        &--delete {
            @include fourg-theme(background-color, 'error');
            @include fourg-theme(color, 'on-error');
            @include fourg-theme(border-color, 'error');
        }

        &--secondary {

        }

        &--submit {

        }
    }
}
