@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-convert-to-subtask-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;
    }

    &__description {
        @include fourg-theme(color, 'soft-on-surface');
        font-size: fourg-px-to-rem(14px);
    }

    &__loader {
        border-radius: 0 0 8px 8px;
    }
}
