@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-icon {
    @include fourg-inline-flex(column, center, center);
    @include fourg-icon-size(24px);
    color: currentColor;
    flex-shrink: 0;
    position: relative;
    margin: 0;
    user-select: none;

    &--has-cover {
        border-radius: 50%;
    }

    &__icon {
        display: block;
        font-size: inherit;
        line-height: inherit;
        position: relative;
    }

    &--has-cover &__icon {
        visibility: hidden;
    }

    &__cover {
        @include fourg-overlay(absolute);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
