// Import browserslist-controlled normalize.
@import-normalize;

@import './functions';
@import './variables';
@import './mixins';

// Public Sans font (style-linked)
// Light (300)
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/PublicSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/PublicSans-LightItalic.woff') format('woff');
}
// Medium (400)
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/PublicSans-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/PublicSans-MediumItalic.woff') format('woff');
}
// Semi-Bold (600)
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/PublicSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/PublicSans-SemiBoldItalic.woff') format('woff');
}
// Bold (700)
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/PublicSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/PublicSans-BoldItalic.woff') format('woff');
}
// Black (900)
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/PublicSans-Black.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 900;
  src: url('./assets/fonts/PublicSans-BlackItalic.woff') format('woff');
}

// Root variables
:root {
  // background colors
  @include fourg-theme(--fourg-theme--primary, 'primary', false);
  @include fourg-theme(--fourg-theme--secondary, 'secondary', false);
  @include fourg-theme(--fourg-theme--background, 'background', false);
  @include fourg-theme(--fourg-theme--surface, 'surface', false);
  @include fourg-theme(--fourg-theme--registration, 'registration', false);
  @include fourg-theme(--fourg-theme--disabled, 'disabled', false);
  @include fourg-theme(--fourg-theme--highlight, 'highlight', false);
  @include fourg-theme(--fourg-theme--error, 'error', false);
  @include fourg-theme(--fourg-theme--warning, 'warning', false);
  @include fourg-theme(--fourg-theme--success, 'success', false);
  // text colors
  @include fourg-theme(--fourg-theme--on-primary, 'on-primary', false);
  @include fourg-theme(--fourg-theme--on-secondary, 'on-secondary', false);
  @include fourg-theme(--fourg-theme--on-background, 'on-background', false);
  @include fourg-theme(--fourg-theme--on-surface, 'on-surface', false);
  @include fourg-theme(--fourg-theme--on-registration, 'on-registration', false);
  @include fourg-theme(--fourg-theme--on-disabled, 'on-disabled', false);
  @include fourg-theme(--fourg-theme--on-highlight, 'on-highlight', false);
  @include fourg-theme(--fourg-theme--on-error, 'on-error', false);
  @include fourg-theme(--fourg-theme--on-warning, 'on-warning', false);
  @include fourg-theme(--fourg-theme--on-success, 'on-success', false);
  // soft colors
  @include fourg-theme(--fourg-theme--soft-on-primary, 'soft-on-primary', false);
  @include fourg-theme(--fourg-theme--soft-on-secondary, 'soft-on-secondary', false);
  @include fourg-theme(--fourg-theme--soft-on-background, 'soft-on-background', false);
  @include fourg-theme(--fourg-theme--soft-on-surface, 'soft-on-surface', false);
  @include fourg-theme(--fourg-theme--soft-on-registration, 'soft-on-registration', false);
  @include fourg-theme(--fourg-theme--soft-on-disabled, 'soft-on-disabled', false);
  @include fourg-theme(--fourg-theme--soft-on-highlight, 'soft-on-highlight', false);
  @include fourg-theme(--fourg-theme--soft-on-error, 'soft-on-error', false);
  @include fourg-theme(--fourg-theme--soft-on-warning, 'soft-on-warning', false);
  @include fourg-theme(--fourg-theme--soft-on-success, 'soft-on-success', false);
  // border colors
  @include fourg-theme(--fourg-theme--border-on-primary, 'border-on-primary', false);
  @include fourg-theme(--fourg-theme--border-on-secondary, 'border-on-secondary', false);
  @include fourg-theme(--fourg-theme--border-on-background, 'border-on-background', false);
  @include fourg-theme(--fourg-theme--border-on-surface, 'border-on-surface', false);
  @include fourg-theme(--fourg-theme--border-on-registration, 'border-on-registration', false);
  @include fourg-theme(--fourg-theme--border-on-disabled, 'border-on-disabled', false);
  @include fourg-theme(--fourg-theme--border-on-highlight, 'border-on-highlight', false);
  @include fourg-theme(--fourg-theme--border-on-error, 'border-on-error', false);
  @include fourg-theme(--fourg-theme--border-on-warning, 'border-on-warning', false);
  @include fourg-theme(--fourg-theme--border-on-success, 'border-on-success', false);
}

// resets
* {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
}

// scrollbar 
::-webkit-scrollbar {
    width: $fourg-scrollbar--size;
    height: $fourg-scrollbar--size;
}
 
::-webkit-scrollbar-track {
  @include fourg-theme(background-color, 'border-on-background');
}

::-webkit-scrollbar-thumb {
  @include fourg-theme(background-color, 'soft-on-background');
  border-radius: 3px;
}

::-webkit-scrollbar-corner {
  @include fourg-theme(background-color, 'border-on-background');
}

html {
  font-size: $fourg-root--font-size;
}

body {
  @include fourg-theme(background-color, 'background');
  @include fourg-theme(color, 'on-background');
  @include fourg-theme(border-color, 'border-on-background');
  font-family: 'Public Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
ul,
ol {
  @include fourg-gutter(margin-bottom);
  margin-top: 0;
  white-space: pre-wrap;
}

h1 {
  font-size: fourg-px-to-rem(48px);
  font-weight: 200;
  line-height: 1.2;
}

h2 {
  font-size: fourg-px-to-rem(40px);
  font-weight: 400;
  line-height: 1.2;
}

h3 {
  font-size: fourg-px-to-rem(32px);
  font-weight: 600;
  line-height: 1.2;
}

h4 {
  font-size: fourg-px-to-rem(24px);
  font-weight: 600;
  line-height: 1.2;
}

h5 {
  font-size: fourg-px-to-rem(20px);
  font-weight: 600;
  line-height: 1.2;
}

h6 {
  font-size: fourg-px-to-rem(18px);
  font-weight: 600;
  line-height: 1.2;
}

p {
  line-height: 1.5;
}

a {
    @include fourg-theme(color, 'primary');
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

address {
  font-style: inherit;
  white-space: pre-wrap;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.fourg-app {
  
}
