@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-filters {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    border: none;

    .fourg-sidebar-section {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');

        &__toggle {
            @include fourg-theme(background-color, 'surface');
            @include fourg-theme(color, 'on-surface');
            @include fourg-theme(border-color, 'border-on-surface');
            @include fourg-gutter(padding-top, 0.75);
            @include fourg-gutter(padding-bottom, 0.75);
        }

        &__sep {
            @include fourg-theme(background-color, 'border-on-surface');
        }

        &__label {
            @include fourg-theme(color, 'soft-on-surface');
            text-transform: uppercase;
            font-size: fourg-px-to-rem(14px);
        }
    }
}
