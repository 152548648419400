@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-announcement-bar {
    @include fourg-theme(background-color, 'highlight');
    @include fourg-theme(color, 'on-highlight');
    @include fourg-theme(border-color, 'border-on-highlight');
  
    &__content {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-gutter(padding-top, 0.75);
        @include fourg-gutter(padding-bottom, 0.75);
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        box-sizing: border-box;
    }

    &__header {
        @include fourg-flex(row, center, flex-start);
    }

    &__title {
        font-size: fourg-px-to-rem(14px);
        font-weight: 700;
        margin: 0;
        flex-grow: 1;
    }

    &__message {
        @include fourg-theme(color, 'soft-on-highlight');
        @include fourg-flatten-children;
    }
}
