@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-recurrence-span {
  
    &__text {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: fourg-px-to-rem(14px);
    }

    &__subtext {
        @include fourg-theme(color, 'soft-on-surface');
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-size: fourg-px-to-rem(12px);
        margin-top: 0.15em;
    }
}
