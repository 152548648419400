@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-page {
    @include fourg-flex(column, stretch, flex-start);
    flex-grow: 1;
    max-height: 100vh;

    &__content {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-gutter(padding);
        flex-grow: 1;
        position: relative;
    }
}
