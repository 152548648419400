@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-table-group-item {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-gutter(margin-bottom);
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-box-shadow;
    border-radius: 4px;
    overflow: hidden;

    &:not(&--expanded) &__content {
        display: none;
    }

    &__toggle {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding, 0.75);
        @include fourg-ripple;
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        border: none;
        box-shadow: none;
        outline: 0;
        font: inherit;
        text-align: inherit;
        font-size: fourg-px-to-rem(14px);
        font-weight: 600;
        cursor: pointer;
    }

    &--expanded &__toggle {
        @include fourg-theme(border-color, 'border-on-surface');
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &--disabled &__toggle {
        cursor: auto;
        pointer-events: none;
    }

    &__caret {
        @include fourg-transition(transform color);
        transform: rotate(0);
    }

    &--disabled &__caret {
        @include fourg-theme(color, 'soft-on-surface');
    }

    &:not(&--expanded) &__caret {
        transform: rotate(180deg);
    }

    &__heading {
        @include fourg-gutter(padding-left, 0.25);
        @include fourg-gutter(padding-right, 0.25);
        margin: 0;
        font: inherit;
        flex-grow: 1;
        box-sizing: border-box;
    }

    &__total {
        @include fourg-theme(color, 'soft-on-surface');
    }

    .fourg-table {
        
        &__thead {
            display: none;
        }
    }
}
