@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-table-cell-by-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--type-address {

    address {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--type-checkbox {
    @include fourg-flex(row, center, flex-start);

    .fourg-icon {
        @include fourg-icon-size(16px);
    }
  }

  &--type-resource,
  &--type-lookup,
	&--type-lookup-input {
    
    .fourg-model-record-info {

      &__icon {
        @include fourg-icon-size(18px, 14px);
      }
    }
  }

  &__empty {
    @include fourg-theme(color, 'border-on-surface');
  }
}
