@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    border-width: 1px;
    border-style: solid;
    position: relative;
    border-radius: 3px;
    box-sizing: border-box;
    width: 100%;

    &--variant-quiet {
        background-color: transparent;
        color: inherit;
        border: none;
    }

    &__header {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-gutter(padding);
				@include fourg-gutter(gap, 0.5);
        border-bottom-width: 1px;
        border-bottom-style: solid;

        .fourg-loader {
            @include fourg-theme(color, 'primary');
        }
    }

    &__sidebar {
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(border-color, 'border-on-background');
        @include fourg-gutter(padding-left);
        @include fourg-gutter(padding-right);
        @include fourg-gutter(padding-top, 0.5);
        @include fourg-gutter(padding-bottom, 0.5);
        flex-shrink: 0;
        overflow-y: auto;
        border-right-width: 1px;
        border-right-style: solid;
        width: 100%;
        max-width: 240px;
        box-sizing: border-box;

        .fourg-menu-item {
            @include fourg-theme(color, 'on-background');

            &__label {
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    &__content {
        @include fourg-flex(row, stretch, flex-start);
        flex-grow: 1;
        overflow-y: auto;
    }

    &__title {
        font-size: fourg-px-to-rem(20px);
        margin-bottom: 0;
        font-weight: 600;
    }

    &__sections {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-gutter(padding, 0.5);
        flex-grow: 1;
        box-sizing: border-box;
        overflow-y: auto;
    }

    &__fields {
        @include fourg-flex(row wrap, stretch, flex-start);
        @include fourg-gutter(padding, 0.5);
        flex-grow: 1;

				&:empty {
					display: none;
				}
    }

    &__actions {
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding);
        @include fourg-theme(border-color, 'border-on-surface');
        @include fourg-flatten-children(true);
        border-top-width: 1px;
        border-top-style: solid;
    }

    &--variant-quiet &__actions {
        border: none;
    }

    &__action {
        @include fourg-gutter(margin-right, 0.5);

        &--cancel {
            @include fourg-theme(color, 'error');
            @include fourg-theme(border-color, 'error');
            margin-right: auto;
        }

        &--delete {
            @include fourg-theme(background-color, 'error');
            @include fourg-theme(color, 'on-error');
            @include fourg-theme(border-color, 'error');
        }

        &--secondary {

        }

        &--submit {

        }
    }
}
