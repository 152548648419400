@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-lookup-input-new {
	@include fourg-gutter(margin-bottom);
	position: relative;
	width: 100%;

	.fourg-select-input {
		@include fourg-gutter(margin-bottom, 0);
	}
}
