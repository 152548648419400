@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-radio-group {
    @include fourg-inline-flex(column, stretch, flex-start);
    @include fourg-gutter(margin-bottom);
    @include fourg-flatten-children;
    position: relative;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    border: none;
    margin: 0;

    &__label {
        @include fourg-gutter(margin-bottom, 0.5);
        font:inherit;
        font-weight: 600;
        font-size: fourg-px-to-rem(16px);
        padding: 0;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    &__validator {
        @include fourg-screen-reader-only;
    }
}
