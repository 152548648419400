@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-checkbox {
    @include fourg-inline-flex(row, center, flex-start);
    @include fourg-gutter(margin-bottom);
    position: relative;
    user-select: none;
    cursor: pointer;

    &--disabled {
        pointer-events: none;
        cursor: auto;
    }
  
    &__input {
        @include fourg-screen-reader-only;
    }

    &__icon {
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-transition(color);
        @include fourg-icon-size(20px);
        display: block;
        flex-shrink: 0;

        &--unchecked {
            display: block;
        }

        &--checked {
            display: none;
        }
    }

    &--checked &__icon,
    &--focused &__icon {
        @include fourg-theme(color, 'primary');
    }

    &--checked &__icon--unchecked {
        display: none;
    }

    &--checked &__icon--checked {
        display: block;
    }
    
    &--disabled &__icon {
        @include fourg-theme(color, 'soft-on-disabled');
    }

    &__label {
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(6px);
        @include fourg-transition(color);
        display: block;
        font-size: fourg-px-to-rem(16px);
        line-height: fourg-px-to-rem(16px);
        font-weight: 400;
    }
    
    &--disabled &__label {
        @include fourg-theme(color, 'on-disabled');
    }
}
