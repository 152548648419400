@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-model-record-info {
    @include fourg-inline-flex(row, center, flex-start);
    max-width: 100%;

    &__icon {
				@include fourg-theme(color, 'primary');
        @include fourg-static-ripple('primary', 'active');
        @include fourg-icon-size(28px);
        border-radius: 50%;
        overflow: hidden;
				position: relative;
    }

    &__label {
        @include fourg-gutter(padding-left, 0.25);
        @include fourg-gutter(padding-right, 0.25);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
