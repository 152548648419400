@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-tab {
    @include fourg-theme(color, 'soft-on-surface');
    @include fourg-transition(color);

    &--active {
        @include fourg-theme(color, 'primary');
    }

    &__action {
        @include fourg-inline-flex(row, center, center);
        @include fourg-gutter(padding-top);
        @include fourg-gutter(padding-bottom);
        @include fourg-gutter(padding-left, 0.5);
        @include fourg-gutter(padding-right, 0.5);
        @include fourg-ripple;
        background-color: transparent;
        border: none;
        box-shadow: none;
        margin: 0;
        font: inherit;
        cursor: pointer;
        color: inherit;
        outline: 0;
        white-space: nowrap;
    }

    &--disabled &__action {
        pointer-events: none;
        cursor: auto;
    }

    &__label {
        text-transform: uppercase;
        font-size: fourg-px-to-rem(14px);
        font-weight: 700;
    }

    .fourg-badge {
        @include fourg-gutter(margin-left, 0.25);
        @include fourg-static-ripple('primary', 'active');
        background-color: transparent;
        color: inherit;
    }
}
