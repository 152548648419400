@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-about-dialog {

    &__tab-bar {
        @include fourg-theme(border-color, 'border-on-surface');
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &__scroller {
        flex-grow: 1;
        overflow-y: auto;
    }

    &__tools {
        @include fourg-theme(background-color, 'background');
        @include fourg-theme(border-color, 'border-on-background');
        border-bottom-width: 1px;
        border-bottom-style: solid;

        .fourg-input {
            margin-bottom: 0;
            cursor: text;
        }
    }

    .fourg-card-list {

        .fourg-card-list-item {

            .fourg-doc-card,
            .fourg-release-card {
                @include fourg-gutter(padding-top, 0.5);
                @include fourg-gutter(padding-bottom, 2);
                border-bottom-width: 1px;
                border-bottom-style: solid;
            }

            &:first-child {

                .fourg-doc-card,
                .fourg-release-card {
                    padding-top: 0;
                }
            }

            &:last-child {

                .fourg-doc-card,
                .fourg-release-card {
                    @include fourg-gutter(padding-bottom);
                    border-bottom: none;
                }
            }
        }
    }
}
