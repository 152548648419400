@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-page-numbers {
  
    &__list {
        @include fourg-flex(row, center, flex-start);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__action {
        @include fourg-theme(color, 'primary');
        @include fourg-flex(row, center, center);
        @include fourg-gutter(padding, 0.25);
        @include fourg-ripple;
        font: inherit;
        cursor: pointer;
        font-size: fourg-px-to-rem(12px);
        border: none;
        padding: 0;
        margin: 0;
        box-shadow: none;
        background-color: transparent;
        outline: 0;

        &:disabled {
            @include fourg-theme(color, 'soft-on-background');
            cursor: auto;
            pointer-events: none;
        }

        &--number {
            font-size: fourg-px-to-rem(14px);

            &:disabled {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    &__action--prev &__action-icon, 
    &__action--start &__action-icon {
        @include fourg-icon-size(16px);
        transform: rotate(90deg);
        margin-left: fourg-px-to-rem(8px) * -1;
    }

    &__action--next &__action-icon, 
    &__action--end &__action-icon {
        @include fourg-icon-size(16px);
        transform: rotate(-90deg);
        margin-right: fourg-px-to-rem(8px) * -1;
    }
}
