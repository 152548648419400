@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-contact-form-dialog {

    &__form {
        border: none;
        border-radius: 0 0 8px 8px;

        .fourg-form__fields {
            @include fourg-flex(row, stretch, flex-start);
            padding: 0;
            overflow: hidden;
        }
    }

    &__loader {
        border-radius: 0 0 8px 8px;
    }

    &__fields {
        @include fourg-flex(row wrap, flex-start, flex-start);
        @include fourg-gutter(padding, 0.5);
        width: 58%;
        overflow-y: auto;
        position: relative;
    }

    &__locations {
        @include fourg-theme(border-color, 'border-on-surface');
        border-left-style: solid;
        border-left-width: 1px;
        width: 42%;
        position: relative;
        box-sizing: border-box;
    }

    &__locations-field {
        @include fourg-overlay(absolute);
        padding: 0;
        
        .fourg-form-field__input {
            height: 100%;
        }
    }
}
