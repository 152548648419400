@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-sidebar-section {
    @include fourg-theme(background-color, 'background');
    @include fourg-theme(color, 'on-background');
    @include fourg-theme(border-color, 'border-on-background');
    @include fourg-flex(column, stretch, flex-start);

    &__toggle {
        @include fourg-theme(background-color, 'background');
        @include fourg-flex(row, center, flex-start);
        @include fourg-gutter(padding);
        @include fourg-ripple;
        font: inherit;
        cursor: pointer;
        color: inherit;
        text-align: unset;
        appearance: none;
        border: none;
        padding: 0;
        margin: 0;
        outline: 0;
        box-sizing: border-box;
        position: sticky;
        top: 0;
        z-index: 3;

        &:disabled {
            @include fourg-kill-ripple;
            pointer-events: none;
            cursor: auto;
        }
    }

    &__toggle-icon {
        @include fourg-transition(transform, opacity);
        transform: rotate(0);
        margin-left: auto;
        opacity: 1;
    }

    &__toggle:disabled &__toggle-icon {
        @include fourg-theme(color, 'soft-on-background');
    }

    &--expanded &__toggle-icon {
        transform: rotate(-180deg);
    }

    &__label {
        font-size: fourg-px-to-rem(18px);
        font-weight: 600;
        margin: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__count {
        @include fourg-gutter(padding-left, 0.4);
        @include fourg-gutter(padding-right, 0.4);
        @include fourg-theme(color, 'soft-on-background');
        font-size: fourg-px-to-rem(14px);
        font-weight: 400;
    }

    &__content {
        @include fourg-flex(column, flex-start, flex-start);
        @include fourg-gutter(padding);
        @include fourg-flatten-children;
        box-sizing: border-box;
    }

    &__sep {
        @include fourg-theme(background-color, 'border-on-background');
        @include fourg-gutter(margin-left);
        @include fourg-gutter(margin-right);
        height: 1px;
        margin-top: 0;
        margin-bottom: 0;
        border: none;
    }

    &:last-child &__sep {
        display: none;
    }

    &:not(&--expanded) &__content {
        display: none;
    }
}