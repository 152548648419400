@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-form-field-by-field {
    
    &--type-textarea {
        width: 100%;
    }
}
