@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-users-page {

	.fourg-page__content {
		padding: 0;
	}

	.fourg-table {
		border-radius: 0;
		flex-grow: 1;
	}
}
