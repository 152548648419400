@import '../../functions';
@import '../../variables';
@import '../../mixins';


.fourg-select-input {
	@include fourg-gutter(margin-bottom);
	position: relative;

	&__label {
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'soft-on-surface');
		@include fourg-transition(font-size top left color background-color);
		left: 14px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 1px;
		padding: 0 3px;
		margin-left: -3px;
		pointer-events: none;
		font-size: fourg-px-to-rem(14px);
		font-weight: 400;
		z-index: 1;
		box-sizing: border-box;
		display: block;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
	}

	&--has-icon &__label {
		left: 54px;
	}

	&__validator {
		@include fourg-screen-reader-only;
	}

	&__control-icon {
		@include fourg-theme(color, 'soft-on-surface');
		margin-inline-start: 14px;
	}

	&--dirty:not(&--focused) &__control-icon {
		display: none;
	}

	&--focused &__label,
	&--dirty &__label {
		left: 14px;
		top: 0;
		font-size: fourg-px-to-rem(12px);
	}

	&--focused &__label {
		@include fourg-theme(color, 'primary');
	}

	&--invalid &__label {
		@include fourg-theme(color, 'error');
	}

	&--disabled &__label {
		@include fourg-theme(background-color, 'disabled');
		@include fourg-theme(color, 'on-disabled');
	}

	body &__rs__control {
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		@include fourg-theme(border-color, 'soft-on-surface');
		@include fourg-transition(background-color color border-color box-shadow);
		font-size: fourg-px-to-rem(14px);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: none;
	}

	body &--focused &__rs__control {
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		@include fourg-theme(border-color, 'soft-on-surface');
		@include fourg-focus-ring;
	}

	body &--invalid &__rs__control {
		@include fourg-theme(color, 'error');
		@include fourg-theme(border-color, 'error');
	}

	body &--invalid.fourg-select-input--focused &__rs__control {
		@include fourg-focus-ring('error');
	}

	body &--disabled &__rs__control {
		@include fourg-theme(background-color, 'disabled');
		@include fourg-theme(color, 'on-disabled');
		@include fourg-theme(border-color, 'border-on-disabled');
		box-shadow: none;
		pointer-events: none;
		cursor: auto;
	}

	body &__rs__value-container {
		margin: 0;
		padding: 0 14px;
		line-height: 1.1;
	}

	body &__rs__single-value {
		@include fourg-inline-flex(row, center, flex-start);
		gap: 14px;
		margin: 0;
		padding: 0;
		max-width: 100%;
		min-width: 0;

		> span {
			min-width: 0;
			flex-grow: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding: 14px 0;
		}

		.fourg-icon {
			flex-shrink: 0;
			border-radius: 50%;
			overflow: hidden;
		}
	}

	body &--focused &__rs__single-value {

		.fourg-icon {
			display: none;
		}
	}

	body &--disabled &__rs__single-value {
		@include fourg-theme(color, 'on-disabled');
	}
	
	body &:not(&--dirty):not(&--focused) &__rs__single-value {
		display: none;
	}
	
	body &__rs__input-container {
		margin: 0;
		padding: 14px 0;
		line-height: 1.1;
	}

	body &__rs__indicator:hover,
	body &__rs__indicator:active,
	body &__rs__indicator:focus,
	body &__rs__indicator {
		color: currentColor;
	}

	body &__rs__indicator-separator {
		visibility: hidden;
	}
	
	body &__rs__placeholder {
		visibility: hidden;
	}
	
	// Note that the menu is portaled.
	body &__rs__menu {
		@include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-box-shadow(2);
		font-size: fourg-px-to-rem(14px);
		z-index: 12;
		border-radius: 3px;
	}

	body &__rs__menu-portal {
		z-index: 12;
	}

	body &__rs__menu-list {
    @include fourg-gutter(padding-top, 0.25);
    @include fourg-gutter(padding-bottom, 0.25);
	}

	body &__rs__option {
		margin: 0;
		padding: 0;
		background-color: transparent !important;
		color: inherit !important;
		font: inherit;
		line-height: inherit;
		letter-spacing: inherit;
	}
}
