@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-input {
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;

    /* &--disabled {
        cursor: auto;
        pointer-events: none;
    } */

    &__container {
        position: relative;
    }

    &--variant-quiet:not(&--focused) &__container {
        @include fourg-ripple;
        border-radius: 3px;
    }

    &__icon {
        @include fourg-theme(color, 'soft-on-surface');
        left: fourg-px-to-rem(14px);
        top: fourg-px-to-rem(14px);
        @include fourg-icon-size(16px);
        position: absolute;
        transform: scale(1.5) translateX(fourg-px-to-rem(2px));
    }

    &--variant-quiet &__icon {
        left: fourg-px-to-rem(6px);
        top: fourg-px-to-rem(8px);
    }

    &__label {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-transition(font-size top left color background-color);
        left: fourg-px-to-rem(14px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 1px;
        padding: 0 3px;
        margin-left: -3px;
        pointer-events: none;
        font-size: fourg-px-to-rem(14px);
        font-weight: 400;
        z-index: 1;
        box-sizing: border-box;
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--has-icon:not(&--dirty, &--focused) &__label {
        left: fourg-px-to-rem(45px);
    }

    &--type-textarea &__label {
        top: fourg-px-to-rem(14px);
        transform: translateY(0);
    }

    &--variant-quiet &__label {
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(6px);
        padding-bottom: fourg-px-to-rem(6px);
        font-size: fourg-px-to-rem(12px);
        position: relative;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
        background-color: transparent;
        margin: 0;
        pointer-events: inherit;
        cursor: pointer;
    }

    &--variant-quiet:not(&--disabled) &__label {
        cursor: pointer;
    }

    &--invalid &__label {
        @include fourg-theme(color, 'error');
    }

    &--focused &__label {
        @include fourg-theme(color, 'primary');
    }

    &--focused.fourg-input--invalid &__label {
        @include fourg-theme(color, 'error');
    }

    &--dirty &__label,
    &--focused &__label {
        left: fourg-px-to-rem(14px);
        top: 0;
        transform: translateY(-50%);
        font-size: fourg-px-to-rem(12px);
    }

    &--disabled &__label {
			@include fourg-theme(background-color, 'disabled');
			@include fourg-theme(color, 'on-disabled');
    }

    &__input {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'soft-on-surface');
        @include fourg-transition(box-shadow border-color background-color color);
        padding-top: fourg-px-to-rem(14px);
        padding-bottom: fourg-px-to-rem(14px);
        text-indent: fourg-px-to-rem(14px);
        font: inherit;
        font-size: fourg-px-to-rem(14px);
        box-sizing: border-box;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        font-weight: 400;
        line-height: 1.1;
        width: 100%;
        max-width: 100%;
        border-radius: 3px;
        outline: 0;
        text-overflow: ellipsis;
        display: block;
    }

    &--has-icon &__input {
        padding-left: fourg-px-to-rem(45px);
        text-indent: 0;
    }

    &--type-textarea &__input {
        padding-left: fourg-px-to-rem(14px);
        padding-right: fourg-px-to-rem(14px);
        text-indent: unset;
        width: 100% !important;
        min-height: 125px;
    }

    &--variant-quiet &__input {
        padding-top: fourg-px-to-rem(8px);
        padding-bottom: fourg-px-to-rem(8px);
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(6px);
        border-color: transparent;
        background-color: transparent;
    }
    
    &--variant-quiet:not(&--type-textarea) &__input {
        text-indent: fourg-px-to-rem(6px);
        padding-left: 0;
        padding-right: 0;
    }

    &--has-icon.fourg-input--variant-quiet &__input {
        padding-left: fourg-px-to-rem(32px);
        text-indent: 0;
    }

    &--invalid &__input {
        @include fourg-theme(border-color, 'error');
        padding-right: fourg-px-to-rem(45px) !important;
    }

    &--focused &__input {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'soft-on-surface');
        @include fourg-focus-ring;
    }
    
    &--focused.fourg-input--invalid &__input {
        @include fourg-focus-ring('error');
    }

    &--disabled &__input {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
        box-shadow: none;
        resize: none;
    }

    &__warning {
        right: fourg-px-to-rem(14px);
        @include fourg-theme(color, 'error');
        @include fourg-transition(opacity);
        @include fourg-icon-size(22px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        pointer-events: none;
    }

    &--variant-quiet &__warning {
        right: fourg-px-to-rem(8px);
    }

    &--type-textarea &__warning {
        top: fourg-px-to-rem(14px);
        transform: translateY(0);
    }

    &--variant-quiet.fourg-input--type-textarea &__warning {
        top: fourg-px-to-rem(6px);
    }

    &--invalid &__warning {
        opacity: 1;
    }
}
