@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-notification-card {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-flex(row, flex-start, flex-start);

    &--read {
        @include fourg-theme(color, 'soft-on-surface');
    }

    &__avatar {
        @include fourg-theme(color, 'primary');
        @include fourg-static-ripple('primary', 'active');
        @include fourg-icon-size(32px, 20px);
        flex-shrink: 0;
        margin-right: fourg-px-to-rem(8px);
        border-radius: 50%;
        overflow: hidden;
    }

    &__header {
        padding: fourg-px-to-rem(7px) 0;
        display: block;
        position: relative;
    }

    &__title {
        font-size: fourg-px-to-rem(14px);
        line-height: 1;
        font-weight: inherit;
        margin-bottom: 0;
        vertical-align: baseline;
        display: inline-block;

        a {
            font-weight: 600;
        }

        strong {
            font-weight: 700;
        }
    }

    &__date {
        @include fourg-theme(color, 'soft-on-surface');
        padding: 0 fourg-px-to-rem(6px);
        font-size: fourg-px-to-rem(12px);
        vertical-align: baseline;
        display: inline-block;
    }

    &__content {
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }

    &__body {
        @include fourg-flatten-children;
        font-size: fourg-px-to-rem(14px);
        word-wrap: break-word;

        strong {
            @include fourg-theme(color, 'primary');
            font-weight: 600;
        }
    }
    
    &__attachment {
        @include fourg-theme(border-color, 'primary');
        @include fourg-gutter(padding-left, 0.75);
        border-left-style: solid;
        border-left-width: 4px;
    }

    &__attachment + &__attachment {
        @include fourg-gutter(margin-top);
    }

    &__subtask {
        max-width: 300px;
    }

    &__info {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-gutter(margin-top, 0.4);
        gap: 4px;

        .fourg-icon {
            @include fourg-icon-size(14px);
        }
        
        p {
            font-size: fourg-px-to-rem(10px);
            margin: 0;
        }
    }

    .fourg-editor {

        .DraftEditor-root {
            padding: 0;
        }
    }
}
