@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-lookup-input {
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;

    &__content {
        position: relative;
    }

    &__search {
        @include fourg-transition(opacity, 100ms);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 2;
        opacity: 1;
        margin-bottom: 0;

        .fourg-input__input {
            @include fourg-gutter(padding-right, 2);
        }

        &:not(.fourg-input--focused) {
            transition-duration: 0ms;
            opacity: 0;
            pointer-events: none;
            
            .fourg-input__input {
                cursor: pointer;
            }
        }
    }

    &__loader {
        @include fourg-theme(color, 'primary');
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        z-index: 2;
    }

    .fourg-select--variant-quiet + &__loader {
        @include fourg-gutter(bottom, 0.4);
        @include fourg-gutter(right, 0.25);
        width: fourg-px-to-rem(16px);
        height: fourg-px-to-rem(16px);
        top: unset;
        transform: unset;
    }

    &__select {
        margin-bottom: 0;
    }

    &__add-button {
        @include fourg-link-button;
    }
}
