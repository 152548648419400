@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-icon-message-dialog {

    .fourg-icon-message {
        
        &__icon {
            @include fourg-theme(color, 'primary');
        }
    }
}
