@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-metric-note {
	@include fourg-inline-flex(row, center, flex-start);
	gap: 12px;
	font-size: fourg-px-to-rem(12px);
	margin: 0;

	&__label {
		font-size: fourg-px-to-rem(13px);
		line-height: 1.5;
		font-weight: 600;
		margin: 0;
		max-width: 100%;
		min-width: 0;
		display: block;
	}

	&__variance {
		font-weight: 600;
	}

	&__metric {
		@include fourg-theme(color, 'soft-on-background');
	}

	&--positive &__variance {
		@include fourg-theme(color, 'success');	
	}

	&--negative &__variance {
		@include fourg-theme(color, 'error');	
	}
}
