@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-cietrade-log-dialog {

	&__content {
		@include fourg-flex(column, stretch, flex-start);
		max-height: 100%;
		min-height: 0;
		flex-grow: 1;
	}

	&__header {
		@include fourg-theme(background-color, 'background');
		@include fourg-theme(border-color, 'border-on-background');
		@include fourg-gutter(padding);
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	&__log-meta {
		@include fourg-flex(row wrap, center, flex-start);
		@include fourg-gutter(gap, 1.5);
	}

	&__log-meta-node {
		@include fourg-inline-flex(row, center, center);
		font-size: fourg-px-to-rem(14px);
		gap: 0.5em;

		code {
			@include fourg-theme(background-color, 'border-on-background');
			font-weight: 700;
			padding: 0 0.5em;
			line-height: 1.5;
			border-radius: 0.25em;
		}
	}

	&__log-meta-node-label {
		@include fourg-theme(color, 'soft-on-background');
		font-size: fourg-px-to-rem(12px);
		font-weight: 700;
	}

	&__entry-cell {
		min-width: 0;
	}

	&__entry-cell-empty {
		@include fourg-theme(color, 'border-on-surface');
	}

	.fourg-table {
		max-height: 100%;
		min-height: 0;

		&__scroller {
			overflow-y: auto;
			max-height: 100%;
		}
	}

	.fourg-form {
		flex-shrink: 0;
	}
}
