@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-release-card {
  @include fourg-flatten-children;
  @include fourg-theme(background-color, 'surface');
  @include fourg-theme(color, 'on-surface');
  @include fourg-theme(border-color, 'border-on-surface');

  &__badge {

    &--current {
      @include fourg-gutter(margin-bottom, 0.25);
    }
  }

  &__version {
    margin-bottom: 0;
  }

  &__date {
    @include fourg-theme(color, 'soft-on-surface');
  }

  &__list {
    @include fourg-flatten-children;
    @include fourg-gutter(margin-bottom);
  }

  &__list-heading {
    font-size: fourg-px-to-rem(20px);
  }

  &__list-items {
    @include fourg-flatten-children;

    &:not(ol) {
      list-style-type: disc;
    }

  }

  &__list-item {
    @include fourg-gutter(margin-bottom, 0.5);
  }
}
