@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-chip-list {
    position: relative;
    box-sizing: border-box;

    &__list {
        @include fourg-flex(row wrap, flex-start, flex-start);
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        @include fourg-gutter(margin, 0.125);
    }

    &__label {
        @include fourg-inline-flex(row, center, flex-start);
        @include fourg-gutter(margin-bottom, 0.5);
        font-size: fourg-px-to-rem(14px);
    }

    &__validator {
        @include fourg-screen-reader-only;
    }
}
