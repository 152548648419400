@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-page {

	.fourg-page__content {
		@include fourg-flex(row, stretch, flex-start);
		padding: 0;
	}

	&__header-status {
		display: inline;

		.fourg-select__ui-expand {
			@include fourg-theme(color, 'on-primary');
			padding: 0 fourg-px-to-rem(4px);
		}
	}

	&__delete-confirm-dialog {

		.fourg-icon-message__icon {
			@include fourg-theme(color, 'error');
		}
	}
}
