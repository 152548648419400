@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-susi-form-container {
	@include fourg-flex(column, center, center);
	@include fourg-gutter(padding-top, 2);
	@include fourg-gutter(padding-bottom, 2);
	width: 100%;
	flex-grow: 1;

	&__logo {
		max-width: 150px;
	}

	&__content {
		@include fourg-flex(column, center, center);
		@include fourg-gutter(gap, 2);
		width: 100%;
		max-width: 400px;
	}
}
