@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-action-list {
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-gutter(padding-top, 0.25);
    @include fourg-gutter(padding-bottom, 0.25);
    @include fourg-box-shadow(2);
    @include fourg-transition(opacity transform);
    font-size: fourg-px-to-rem(14px);
    position: absolute;
    top: 0;
    left: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 12;
    border-radius: 3px;
    pointer-events: none;
    overflow: hidden;
    box-sizing: border-box;
    width: auto;
    min-width: 100%;
    opacity: 0;
    transform: translateY(-8px);
    overflow-y: auto;
		text-align: initial;

    &--expanded {
        opacity: 1;
        transform: translateY(0);
        pointer-events: inherit;
    }

    &--anchor-top-right {
        left: unset;
        right: 0;
    }

    &--anchor-bottom-left {
        top: unset;
        bottom: 0;
        transform: translateY(8px);
    }

    &--anchor-bottom-right {
        top: unset;
        left: unset;
        bottom: 0;
        right: 0;
        transform: translateY(8px);
    }
  
}
