@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-company-sidebar {

    &__details {

        .fourg-sidebar-section__content {
            padding: 0;
        }
    }
    
    &__location-details {

        .fourg-sidebar-section__content {
            padding: 0;
        }
    }
}
