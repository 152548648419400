@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-tasks-page {

	&__group-by {
		@include fourg-gutter(margin-right, 0.5);
	}

	&__content {
		@include fourg-flex(row, stretch, flex-start);
		flex-grow: 1;
	}

	&__data {
		@include fourg-flex(column, stretch, flex-start);
		flex-grow: 1;
		width: 100%;
		min-width: 0;
	}

	&__sidebars {
		@include fourg-flex(row, stretch, flex-start);
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		@include fourg-theme(border-color, 'border-on-surface');
		position: relative;
		border-right-width: 0;
		border-left-width: 1px;
		border-left-style: solid;
		width: 100%;
		max-width: 360px;
		z-index: 8;
	}

	.fourg-page__content {
		padding: 0;
	}
}
