@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-attachment-card {
    @include fourg-flex(column, flex-start, flex-start);
    @include fourg-flatten-children;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    position: relative;

    &--variant-card {
        @include fourg-flex(column, stretch, flex-start);
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');
        border-width: 1px;
        border-style: solid;
    }

    &--variant-mini {
        @include fourg-flex(row, flex-start, flex-start);
    }

    &__content {
        @include fourg-flatten-children;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }

    &--variant-card &__content {
        @include fourg-gutter(padding, 0.5);
    }

    &--variant-mini &__content {
        @include fourg-gutter(padding-left, 0.5);
    }

    &__header {
        @include fourg-flex(row, center, flex-start);
        margin-bottom: fourg-px-to-rem(4px);
        width: 100%;
    }

    &__title-link {
        @include fourg-inline-flex(row, center, flex-start);
        color: inherit;
        max-width: 100%;
        flex-grow: 1;
        min-width: 0;
    }

    &__title {
        font-size: fourg-px-to-rem(13px);
        font-weight: 600;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__media {
        @include fourg-gutter(margin-bottom, 0.5);
        overflow: hidden;
        position: relative;
        width: 100%;
        display: block;
        flex-shrink: 0;

        &--type-image {
            @include fourg-theme(background-color, 'border-on-background');
            @include fourg-theme(color, 'on-background');
            @include fourg-theme(border-color, 'border-on-background');
            @include fourg-cover-background;
            padding-top: 56.25%;
            border-width: 1px;
            border-style: solid;
            border-radius: 6px;

            > img,
            > span {
                @include fourg-screen-reader-only;
            }
        }
        
        &--type-video {
            @include fourg-theme(background-color, 'border-on-background');
            @include fourg-theme(color, 'on-background');
            @include fourg-theme(border-color, 'border-on-background');
            padding-top: 56.25%;
            border-width: 1px;
            border-style: solid;
            border-radius: 6px;

            > iframe,
            > video {
                @include fourg-overlay(absolute);
            }
            
            .fourg-icon {
                @include fourg-theme(color, 'on-registration');
                @include fourg-overlay(absolute);

                &__icon {
                    z-index: 1;
                }
            }
        }

        &--type-audio {

            .fourg-icon {
                @include fourg-theme(color, 'on-registration');
                @include fourg-overlay(absolute);

                &__icon {
                    z-index: 1;
                }
            }
        }

        &--type-file {
            @include fourg-theme(color, 'primary');
            max-width: 40px;
        }
    }

    &--variant-mini &__media {
        margin: 0;
    }

    &--variant-card &__media {
        @include fourg-theme(background-color, 'border-on-surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'border-on-surface');
        display: block;
        padding-top: 56.25%;
        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        border: none;
        margin: 0;
        width: 100%;
        max-width: unset;

        .fourg-media-icon {
            @include fourg-overlay(absolute);
            box-sizing: border-box;
            padding: 10%;
        }
    }

    &--variant-mini &__media--type-audio,
    &--variant-mini &__media--type-video,
    &--variant-mini &__media--type-image {
        width: 40px;
        height: 40px;
        padding: 0;
    }

    &--variant-mini &__media--type-audio {
        @include fourg-theme(background-color, 'border-on-background');
        @include fourg-theme(color, 'on-background');
        @include fourg-theme(border-color, 'border-on-background');
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;

        .fourg-icon {
            @include fourg-theme(color, 'on-registration');
            @include fourg-overlay(absolute);

            &__icon {
                z-index: 1;
            }
        }
    }

    &--variant-mini &__media--type-file {
        @include fourg-flex(row, center, center);

        .fourg-media-icon {
            height: 40px;
            width: auto;
        }
    }

    &__description {
        @include fourg-theme(color, 'soft-on-background');
        @include fourg-gutter(margin-bottom, 0.5);
        font-size: fourg-px-to-rem(12px);
    }

    &--variant-card &__description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__links {

        a {
            @include fourg-gutter(margin-right, 0.5);
            font-size: fourg-px-to-rem(12px);
            font-weight: 600;
        }
    }

    &__actions {
        @include fourg-transition(opacity);
        opacity: 0;
        flex-shrink: 0;

        .fourg-action-toggle__button {
            color: inherit;
        }
    }
    
    &__actions.fourg-action-toggle--expanded,
    &:hover &__actions {
        opacity: 1;
    }
}
