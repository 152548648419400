@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-quick-view {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    width: 100%;

    &__header {
        @include fourg-theme(border-bottom-color, 'border-on-surface');
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    &__content {
        @include fourg-flex(column, stretch, flex-start);
        flex-grow: 1;
        position: relative;
    }

    &__activity {
        @include fourg-flex(column, stretch, flex-start);
        flex-grow: 1;
    }

    &__details-scroller {
        @include fourg-overlay(absolute);
        overflow-y: auto;
    }
}
