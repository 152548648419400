@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-notifications-dialog {

    &__cards {
        position: relative;
        flex-grow: 1;

    }
  
    &__scroller {
        @include fourg-overlay(absolute);
        overflow-y: auto;
        // flex-grow: 1;
    }

    &__start {
        
    }

    &__end {
        @include fourg-flex(row, center, center);
        @include fourg-flatten-children(true);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;

        .fourg-loader-pill {
            @include fourg-gutter(margin, 0.5);
        }
    }

    &__actions {
        @include fourg-flex(row, center, flex-end);
        @include fourg-gutter(padding);
        @include fourg-theme(border-color, 'border-on-surface');
        border-top-style: solid;
        border-top-width: 1px;
        box-sizing: border-box;
    }

    &__not-found-message {
        @include fourg-overlay(absolute);
    }

    .fourg-dialog__surface {
        height: 90vh;
    }

    .fourg-dialog__content {
        @include fourg-flex(column, stretch, flex-start);
        overflow-y: hidden;
    }

    .fourg-card-list {
        margin-bottom: 0;
    }
}
