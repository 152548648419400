@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-loader-pill {
    @include fourg-flex(row, center, flex-start);
    @include fourg-theme(background-color, 'surface');
    @include fourg-theme(color, 'on-surface');
    @include fourg-theme(border-color, 'border-on-surface');
    @include fourg-gutter(padding-top, 0.25);
    @include fourg-gutter(padding-bottom, 0.25);
    @include fourg-gutter(padding-left, 0.25);
    @include fourg-gutter(padding-right);
    @include fourg-box-shadow(1);
    border-width: 1px;
    border-style: solid;
    z-index: 2;
    border-radius: 40px;
    font-size: fourg-px-to-rem(14px);
    font-weight: 600;

    .fourg-loader {
        @include fourg-theme(color, 'primary');
        @include fourg-gutter(margin-right, 0.5);
    }
}
