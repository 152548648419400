@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-select {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;
    
    // native styles
    &__native {
        @include fourg-screen-reader-only;
    }

    &__label {
        cursor: pointer;
    }

    &__select {
        cursor: pointer;
    }
    
    // ui styles
    &--variant-link,
    &--variant-mini {
        @include fourg-inline-flex(column, flex-start, flex-start);
        margin-bottom: 0;
        width: auto;
    }

    &__ui {
        position: relative;
    }

    &__ui-preview {
        @include fourg-transition(opacity);
        position: relative;
        opacity: 1;
    }

    &--expanded.fourg-select--list-overlaid &__ui-preview {
        opacity: 0;
    }

    &__ui-icon {
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-icon-size(16px);
        left: fourg-px-to-rem(14px);
        top: fourg-px-to-rem(14px);
        position: absolute;
        transform: scale(1.5) translateX(fourg-px-to-rem(2px));
        overflow: hidden;
    }

    &--variant-quiet &__ui-icon {
        left: fourg-px-to-rem(6px);
        top: fourg-px-to-rem(8px);
    }

    &__ui-label {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'soft-on-surface');
        @include fourg-transition(font-size top left color background-color);
        left: fourg-px-to-rem(14px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 1px;
        padding: 0 3px;
        margin-left: -3px;
        pointer-events: none;
        font-size: fourg-px-to-rem(14px);
        font-weight: 400;
        z-index: 1;
        box-sizing: border-box;
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--has-icon:not(&--dirty, &--focused) &__ui-label {
        left: fourg-px-to-rem(45px);
    }

    &--variant-quiet &__ui-label {
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(6px);
        padding-bottom: fourg-px-to-rem(6px);
        font-size: fourg-px-to-rem(12px);
        position: relative;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
        background-color: transparent;
        margin: 0;
        pointer-events: inherit;
    }

    &--variant-quiet:not(&--disabled) &__ui-label {
        cursor: pointer;
    }

    &--variant-link &__ui-label,
    &--variant-mini &__ui-label {
        display: none;
    }

    &--invalid &__ui-label {
        @include fourg-theme(color, 'error');
    }

    &--focused &__ui-label {
        @include fourg-theme(color, 'primary');
    }

    &--focused.fourg-select--invalid &__ui-label {
        @include fourg-theme(color, 'error');
    }

    &--dirty:not(&--variant-quiet) &__ui-label {
        left: fourg-px-to-rem(14px);
        top: 0;
        font-size: fourg-px-to-rem(12px);
    }

    &--disabled &__ui-label {
        @include fourg-theme(color, 'on-disabled');
    }

    &--disabled:not(&--variant-quiet) &__ui-label {
        @include fourg-theme(background-color, 'disabled');
    }

    &__ui-expand-container {
        position: relative;
        border-radius: 3px;
    }

    &--variant-quiet:not(&--focused):not(&--disabled) &__ui-expand-container {
        @include fourg-ripple;
    }

    &__ui-value {
        @include fourg-transition(opacity);
        display: block;
        opacity: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--variant-mini &__ui-value {
        min-height: unset;
    }

    &--variant-quiet &__ui-value,
    &--dirty &__ui-value {
        opacity: 1;
    }

    &__ui-expand {
        @include fourg-flex(row, center, flex-start);
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'soft-on-surface');
        @include fourg-transition(box-shadow border-color background-color color);
        padding-top: fourg-px-to-rem(14px);
        padding-bottom: fourg-px-to-rem(14px);
        padding-left: fourg-px-to-rem(14px);
        padding-right: fourg-px-to-rem(45px);
        font: inherit;
        cursor: pointer;
        font-size: fourg-px-to-rem(14px);
        font-weight: 400;
        line-height: 1.1;
        appearance: none;
        box-sizing: border-box;
        margin: 0;
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        width: 100%;
        max-width: 100%;
        border-radius: 3px;
        outline: 0;
        white-space: nowrap;
        text-align: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--has-icon &__ui-expand {
        padding-left: fourg-px-to-rem(45px);
    }

    &--variant-quiet &__ui-expand {
        padding-top: fourg-px-to-rem(8px);
        padding-bottom: fourg-px-to-rem(8px);
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(45px);
        border-color: transparent;
        background-color: transparent;
    }

    &--variant-mini &__ui-expand {
        @include fourg-ripple;
        @include fourg-kill-focus-ring;
        background-color: transparent;
        border-color: transparent;
        width: auto;
        padding-top: fourg-px-to-rem(8px);
        padding-bottom: fourg-px-to-rem(8px);
        padding-left: fourg-px-to-rem(6px);
        padding-right: fourg-px-to-rem(20px);
    }

    &--variant-link &__ui-expand {
        @include fourg-kill-focus-ring;
        background-color: transparent;
        width: auto;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        padding: 0;
        border: none;
    }

    &--variant-link:not(.fourg-select--disabled) &__ui-value {
        
        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }

    &--has-icon.fourg-select--variant-quiet &__ui-expand {
        padding-left: fourg-px-to-rem(32px);
    }

    &--invalid &__ui-expand {
        @include fourg-theme(border-color, 'error');
    }

    &--focused:not(&--variant-link) &__ui-expand {
        @include fourg-theme(background-color, 'surface');
        @include fourg-theme(color, 'on-surface');
        @include fourg-theme(border-color, 'soft-on-surface');
        @include fourg-focus-ring;
    }
    
    &--focused:not(&--variant-link).fourg-select--invalid &__ui-expand {
        @include fourg-focus-ring('error');
    }

    &--disabled &__ui-expand {
        pointer-events: none;
        cursor: auto;
    }

    &--disabled:not(&--variant-link) &__ui-expand {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
        box-shadow: none;
    }

    &__ui-caret {
        @include fourg-icon-size(20px);
        right: fourg-px-to-rem(14px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        flex-shrink: 0;
    }

    &--variant-quiet &__ui-caret {
        right: 0;
    }

    &--variant-mini &__ui-caret {
        right: 0;
    }

    &--variant-link &__ui-caret {
        font-size: 0.96em;
        line-height: 0.96em;
        height: 0.96em;
        width: 0.96em;
        margin-right: -0.2em;
        position: relative;
        top: unset;
        right: unset;
        transform: unset;
    }

    /* &--variant-mini &__ui-actions,
    &--variant-link &__ui-actions {
        width: auto;
        min-width: 100%;
    } */
}
