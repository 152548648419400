@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-report-detail-dialog {

	&__scroller {
		height: 100%;
		overflow: auto;
	}

	&__worksheets {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: flex-start;
		column-gap: 16px;
		row-gap: 10px;
		margin-top: 8px;
	}

	&__worksheet-info {
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: flex-start;
		gap: 6px;

		.fourg-date-span {
			font-size: 11px;
		}
	}
}
