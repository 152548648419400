@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-unique-input {
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;

    &__content {
        position: relative;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 1;
        margin-bottom: 0;

        .fourg-input__input {
            @include fourg-gutter(padding-right, 2);
        }
    }

    &__select {
        margin-bottom: 0;

        .fourg-select__ui-preview {
            opacity: 0;
        }

        .fourg-action-list-item__action {
            pointer-events: none;
        }
    }

    &__validator {
        @include fourg-screen-reader-only;
    }

    &__validation-icon,
    &__loader {
        @include fourg-theme(color, 'primary');
        @include fourg-icon-size(18px);
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        z-index: 2;
    }

    &__validation-icon {

        &--unavailable {
            @include fourg-theme(color, 'error');
        }

        &--available {
            @include fourg-theme(color, 'success');
        }
    }

    .fourg-select--variant-quiet + &__validation-icon,
    .fourg-select--variant-quiet + &__loader {
        @include fourg-gutter(bottom, 0.4);
        @include fourg-gutter(right, 0.25);
        @include fourg-icon-size(16px);
        width: fourg-px-to-rem(16px);
        height: fourg-px-to-rem(16px);
        top: unset;
        transform: unset;
    }

		&--disabled &__validation-icon {
			display: none;
		}

		&--disabled &__input {
			.fourg-input__input {
					@include fourg-gutter(padding-right, 0);
			}
	}
}
