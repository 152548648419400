@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-table {
	display: block;
	position: relative;
	flex-grow: 1;
	width: 100%;
	min-width: 0;
	box-sizing: border-box;

	&--empty &__scroller,
	&--loading &__scroller {
		min-height: 300px;
	}

	&--scrollable &__scroller {
		@include fourg-overlay(absolute);
		overflow-y: scroll;
		min-height: unset;
	}

	&--disabled &__tr {
		pointer-events: none;
	}

	&__table {
		table-layout: fixed;
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		position: relative;
		vertical-align: middle;
		text-align: unset;
		padding: 0;
	}

	// &__thead {}

	&__th {
		@include fourg-theme(background-color, 'background');
		@include fourg-theme(color, 'on-background');
		text-align: inherit;
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	&__thead &__th {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	&__tbody {
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		@include fourg-theme(border-color, 'border-on-surface');
	}

	&__tbody &__tr {
		@include fourg-table-row-ripple('primary');
		cursor: pointer;

		&:disabled {
			cursor: auto;
		}

		&--error {
			@include fourg-table-row-static-ripple('error', 'focus');
			@include fourg-theme(color, 'error');
			// border-left: 4px solid;
		}

		&--active {
			@include fourg-table-row-static-ripple('primary', 'focus');
		}

		&--alternate {
			@include fourg-theme(background-color, 'background');
			@include fourg-theme(color, 'soft-on-background');

			.fourg-badge {
				opacity: 0.5;
			}
		}
	}

	&__order-button {
		@include fourg-flex(row, center, flex-start);
		@include fourg-gutter(padding-top, 0.75);
		@include fourg-gutter(padding-bottom, 0.75);
		@include fourg-gutter(padding-left, 0.5);
		@include fourg-gutter(padding-right, 0.5);
		@include fourg-theme(border-color, 'border-on-surface');
		@include fourg-ripple();
		background-color: transparent;
		text-align: inherit;
		font-size: fourg-px-to-rem(14px);
		font-weight: 700;
		width: 100%;
		outline: 0;
		color: inherit;
		cursor: pointer;
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom-width: 1px;
		border-bottom-style: solid;

		&:disabled {
			pointer-events: none;
			cursor: auto;
		}
	}

	&__row-actions {
		@include fourg-gutter(padding-left, 0.5);
		@include fourg-gutter(padding-right, 0.5);
	}

	&__order-button-label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: fourg-px-to-rem(20px);
		flex-grow: 1;
	}

	&__order-button-icon {
		@include fourg-icon-size(20px);
	}

	&__order-button-caret {
		@include fourg-transition(transform);
		@include fourg-icon-size(20px);
		margin-left: auto;
	}

	&--desc &__order-button-caret {
		transform: rotate(-180deg);
	}

	&__th--warnings &__order-button,
	&__th--actions &__order-button {
		padding-left: 0 !important;
		padding-right: 0 !important;
		justify-content: center;
	}

	&__th--warnings &__order-button-caret,
	&__th--actions &__order-button-caret {
		margin: 0;
		transform: rotate(0);
	}

	&__td {
		@include fourg-gutter(padding-top, 0.75);
		@include fourg-gutter(padding-bottom, 0.75);
		@include fourg-gutter(padding-left, 0.5);
		@include fourg-gutter(padding-right, 0.5);
		@include fourg-theme(border-color, 'border-on-surface');
		margin: 0;
		text-align: inherit;
		font-weight: 600;
		font-size: fourg-px-to-rem(14px);
		border-bottom-width: 1px;
		border-bottom-style: solid;
		box-sizing: border-box;
	}

	&__value {
		@include fourg-flex(row, center, flex-start);
	}

	&__row-radio {
		@include fourg-screen-reader-only;
	}

	&__row-warnings-icon {
		@include fourg-icon-size(18px);
		pointer-events: none;
	}

	&__tr &__th:first-child &__order-button,
	&__tr &__td:first-child {
		@include fourg-gutter(padding-left);
	}

	&__tr &__th:last-child &__order-button,
	&__tr &__td:last-child {
		@include fourg-gutter(padding-right);
	}

	/* &__tr:last-child &__td {
        border-bottom: none;
    } */

	&__td--actions,
	&__th--actions {
		@include fourg-gutter(width, 2);
		text-align: center;
		padding: 0 !important;
	}

	&__td--warnings,
	&__th--warnings {
		@include fourg-gutter(width, 3);
		text-align: center;
		padding: 0 !important;
	}

	&__row-warnings {
		@include fourg-flex(row, center, center);
		@include fourg-theme(color, 'on-error');
		cursor: help;
		gap: 4px;
		
		&::before {
			@include fourg-theme(background-color, 'error');
			@include fourg-overlay(absolute);
			content: '';
			display: block;
		}

		> span {
			font-size: fourg-px-to-rem(14px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
		}
	}

	&__not-found {
		@include fourg-overlay(absolute);
		z-index: 0;
	}


	&__loader {
		z-index: 0;
		background-color: transparent;
	}
}
