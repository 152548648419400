@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-chip-checkbox-list {
  
    &__validator {
        @include fourg-screen-reader-only;
    }

    .fourg-chip-checkbox {

        &:last-of-type {
            border-bottom: none;
        }
    }
}
