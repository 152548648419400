@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-chip-checkbox {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-gutter(padding-top, 0.5);
    @include fourg-gutter(padding-bottom, 0.5);
    @include fourg-theme(border-color, 'border-on-surface');
    border-bottom-width: 1px;
    border-bottom-style: solid;

    .fourg-checkbox {
        margin: 0;
    }

    .fourg-chip-list {
        @include fourg-gutter(margin-top, 0.5);
    }
}
