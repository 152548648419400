@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-sidebar {

    &__pnl,
    &__subtasks,
    &__members,
    &__attachments,
    &__reminders,
    &__details {

        .fourg-sidebar-section__content {
            padding: 0;
        }
    }
}
