@import '~react-toastify/dist/ReactToastify.css';
@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-toast {
	@include fourg-theme(background-color, 'surface');
	@include fourg-theme(color, 'on-surface');
	@include fourg-theme(border-color, 'border-on-surface');
	@include fourg-flex(row, center, flex-start);
	@include fourg-gutter(padding, 0.5);
	@include fourg-gutter(margin-bottom);
	@include fourg-box-shadow;
	font: inherit;
	border-radius: 3px;
	height: auto;
	box-sizing: border-box;

	&__body {
		@include fourg-flex(row, center, flex-start);
		@include fourg-gutter(padding, 0.5);
		margin: 0;
		flex-grow: 1;
	}

	&__progress {
		@include fourg-theme(background-color, 'primary');
		height: 2px;
	}

	// Info
	&.Toastify__toast--info {
		@include fourg-theme(background-color, 'primary');
		@include fourg-theme(color, 'on-primary');
		@include fourg-theme(border-color, 'border-on-primary');
	}

	&.Toastify__toast--info &__progress {
		@include fourg-theme(background-color, 'soft-on-primary');
	}

	// Success
	&.Toastify__toast--success {
		@include fourg-theme(background-color, 'success');
		@include fourg-theme(color, 'on-success');
		@include fourg-theme(border-color, 'border-on-success');
	}

	&.Toastify__toast--success &__progress {
		@include fourg-theme(background-color, 'soft-on-success');
	}

	// Warning
	&.Toastify__toast--warning {
		@include fourg-theme(background-color, 'warning');
		@include fourg-theme(color, 'on-warning');
		@include fourg-theme(border-color, 'border-on-warning');
	}

	&.Toastify__toast--warning &__progress {
		@include fourg-theme(background-color, 'soft-on-warning');
	}

	// Error
	&.Toastify__toast--error {
		@include fourg-theme(background-color, 'error');
		@include fourg-theme(color, 'on-error');
		@include fourg-theme(border-color, 'border-on-error');
	}

	&.Toastify__toast--error &__progress {
		@include fourg-theme(background-color, 'soft-on-error');
	}

	.fourg-button {
		color: inherit;
	}
}

.fourg-toaster {
	@include fourg-gutter(padding-right);
	padding-bottom: 0;
	padding-top: 0;
	padding-left: 0;
	box-sizing: border-box;
	width: 100%;
	max-width: 425px;
	bottom: 0;
	right: 0;

	@media print {
		display: none;
	}
}

@media only screen and (max-width: 480px) {

	.fourg-toaster {
		@include fourg-gutter(padding-left);
		@include fourg-gutter(padding-right);
		max-width: 100%;
	}
}
