@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-resource-section-quick-add-form-dialog {

	&__form {
		@include fourg-flex(column, stretch, flex-start);
		@include fourg-theme(background-color, 'surface');
		@include fourg-theme(color, 'on-surface');
		position: relative;
		width: 100%;
		border-radius: 0 0 8px 8px;
		max-height: 100%;
	}

	&__form-fields {
		@include fourg-gutter(padding, 0.5);
		box-sizing: border-box;
		overflow-y: auto;
	}

	&__loader {
		border-radius: 0 0 8px 8px;
	}

	.fourg-address-input {
			
		&__content {
			@include fourg-flex(column, stretch, flex-start);
			@include fourg-gutter(gap);
		}

		&__country,
		&__address1,
		&__address2,
		&__city,
		&__state-select,
		&__state-input,
		&__zip {
			width: 100%;
			margin: 0
		}

		&__label {
			display: none;
		}
	}
}
