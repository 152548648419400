@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-expense-card {
	@include fourg-theme(background-color, 'surface');
	@include fourg-theme(color, 'on-surface');
	@include fourg-theme(border-color, 'border-on-surface');
	@include fourg-box-shadow(1);
	@include fourg-flex(row, center, flex-start);
	padding: 12px;
	border-radius: 2px;
	gap: 12px;
	font-size: fourg-px-to-rem(12px);
	position: relative;
	box-sizing: border-box;
	max-width: 100%;

	&--disabled {
			cursor: auto;
			pointer-events: none;
	}

	&--variant-quiet {
		@include fourg-theme(color, 'on-background');
		@include fourg-theme(border-color, 'border-on-background');
		background-color: transparent;
		box-shadow: none;
		padding: 0;
	}

	&__title {
		font-size: fourg-px-to-rem(13px);
		line-height: 1.5;
		font-weight: 600;
		margin: 0;
		max-width: 100%;
		min-width: 0;
		display: block;
	}

	&__cost {
		@include fourg-theme(color, 'soft-on-background');
		flex-shrink: 0;
	}

	&__actions {
		margin-inline-end: -4px;
		margin-inline-start: auto;

		.fourg-action-toggle__button {
			@include fourg-transition(opacity);
			color: inherit;
			opacity: 0;
		}
	}

	&:hover &__actions .fourg-action-toggle__button,
	&__actions.fourg-action-toggle--expanded .fourg-action-toggle__button,
	&__actions .fourg-action-toggle__button:focus,
	&__actions .fourg-action-toggle__button:active {
			opacity: 1;
	}
}
