@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-chip {
    @include fourg-inline-flex(row, center, center);
    @include fourg-theme(background-color, 'background');
    @include fourg-theme(color, 'soft-on-background');
    @include fourg-theme(border-color, 'border-on-background');
    @include fourg-transition(color background-color border-color box-shadow);
    @include fourg-ripple;
    font: inherit;
    background-color: transparent;
    appearance: none;
    font-size: fourg-px-to-rem(14px);
    min-width: 72px;
    max-width: 100%;
    padding: fourg-px-to-rem(6px) fourg-px-to-rem(16px);
    margin: 0;
    border-width: 1px;
    border-style: solid;
    flex-shrink: 0;
    font-weight: 600;
    border-radius: 20px;
    outline: 0;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;

    &--variant-mini {
        min-width: 64px;
        font-size: fourg-px-to-rem(12px);
        padding: fourg-px-to-rem(4px) fourg-px-to-rem(8px);
    }

    &--active {
        @include fourg-theme(color, 'on-background');
        @include fourg-static-ripple(currentColor, 'active');
        padding: fourg-px-to-rem(6px) fourg-px-to-rem(16px) fourg-px-to-rem(6px) 0;
    }

    &--variant-mini.fourg-chip--active {
        padding: fourg-px-to-rem(4px) fourg-px-to-rem(8px) fourg-px-to-rem(4px) 0;
    }

    &:disabled {
        @include fourg-theme(background-color, 'disabled');
        @include fourg-theme(color, 'on-disabled');
        @include fourg-theme(border-color, 'border-on-disabled');
        @include fourg-kill-ripple;
        cursor: auto;
        pointer-events: none;
    }

    &__icon {
        @include fourg-icon-size(18px);
        padding: 0 fourg-px-to-rem(8px);
    }

    &--variant-mini &__icon {
        @include fourg-icon-size(14px);
        padding: 0 fourg-px-to-rem(4px) 0 fourg-px-to-rem(6px);
    }

    &__label {
        display: block;
        line-height: 1.5;
    }
}
