@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-task-form-dialog {

	&__form {
		border: none;
		border-radius: 0 0 8px 8px;
	}

	&__loader {
		border-radius: 0 0 8px 8px;
	}

	&--add.fourg-task-form-dialog--board-targets .fourg-form-field-by-field--name-companyId,
	&--add.fourg-task-form-dialog--board-targets .fourg-form-field-by-field--name-locationId {
		display: none;
	}

	.fourg-address-input {

		&__content {
			@include fourg-flex(row, center, flex-start);
			@include fourg-gutter(gap);
		}

		&__city,
		&__state-select,
		&__state-input,
		&__country {
			width: 100%;
			margin: 0;
			flex-grow: 1;
		}

		&__label {
			display: none;
		}
	}
}
