@import '../../functions';
@import '../../variables';
@import '../../mixins';

.fourg-select-multiple {
    @include fourg-flex(column, stretch, flex-start);
    @include fourg-gutter(margin-bottom);
    position: relative;
    width: 100%;

    // native styles
    &__native {
        @include fourg-screen-reader-only;
    }

    // ui styles
    &__ui-actions {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
    }
}
